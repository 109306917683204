import * as angular from 'angular';

'use strict';

angular.module('ManagerCtrl')
       .controller('ManagerController', ManagerController);

ManagerController.$inject = ['$scope', 'Manager', 'Auth', '$state', '$templateCache', '$window',
    '$interval', '$sce', 'User'];

function ManagerController ($scope, Manager, Auth, $state, $templateCache, $window, $interval, $sce, User) {

    let vm = this;
    vm.stateName = $state.current.name;

    vm.$onInit = async function () {
        try
        {
            $templateCache.removeAll();

            if (typeof ($state.params) != 'undefined' && typeof ($state.params.cartolyticsCustomerID) != 'undefined') {
                vm.cartolyticsCustomerID = $state.params.cartolyticsCustomerID;
            }

        } catch (e) {
            console.log(e);
        }
    }

    vm.getCartolyticsCustomers = async function () {
        try
        {
            let data = await Manager.getCartolyticsCustomers();
            vm.cartolyticsCustomers = data.data.data;

        } catch (e) {
            console.log(e);
        }

    }

    vm.getSidebarMenuItems = async function () {
        try
        {
            let data = await Manager.getSidebarMenuItems();
            vm.sidebarMenuItems = data.data.data;

        } catch (e) {
            console.log(e);
        }

    }

    vm.getCartolyticsCustomerByID = async function () {
        try
        {
            let data = await Manager.getCartolyticsCustomerByID(vm.cartolyticsCustomerID);
            vm.cartolyticsCustomerDetails = data.data.data;

        } catch (e) {
            console.log(e);
        }

    }

    vm.createCartolyticsCustomer = async function () {
        try
        {
                let cartolyticsCustomerModel= await Manager.createCartolyticsCustomer(vm.createCartolyticsCustomerData);
				alert('Instance Created');
 	await vm.getCartolyticsCustomers();
	$scope.$apply()

        } catch (e) {
            console.log(e);
        }

    }

    vm.createEmptyJobModule = async function () {
        try
        {
            vm.createModuleData.cartolyticsCustomerID = vm.cartolyticsCustomerDetails.id;

            let jobModel = await Manager.createEmptyJobModule(vm.createModuleData);

            // TODO: Find a way to get the success as pattern for something that finished
            if (jobModel.data) {
                alert('job created');
            }

        } catch (e) {
            console.log(e);
        }

    }

}
