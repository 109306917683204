import * as angular from 'angular';

'use strict';

angular.module('ManagerService')
       .factory('Manager', ManagerService)
       .config(ManagerServiceConfig);

ManagerService.$inject = ['$http', 'Configuration'];

function ManagerService ($http, Configuration){

    let apiUrl = Configuration.getApiUrl();

    return {
        getCartolyticsCustomers: function getCartolyticsCustomers() {
            let apiBase = apiUrl + 'get-cartolytics-customers';
            let config = {
                params: {}
            };
            return $http.get(apiBase, config);
        },
        getCartolyticsCustomerByID: function getCartolyticsCustomerByID(cartolyticsCustomerIDToQuery) {
            let apiBase = apiUrl + 'get-cartolytics-customer-by-id';
            let config = {
                params: {
                    cartolyticsCustomerIDToQuery: cartolyticsCustomerIDToQuery
                }

            };
            return $http.get(apiBase, config);
        },
        getSidebarMenuItems: function getSidebarMenuItems() {
            let apiBase = apiUrl + 'get-sidebar-menu-items';
            let config = {
                params: {}
            };
            return $http.get(apiBase, config);
        },

        /**
         * Creates a new Cartolytics customer.
         *
         * @param createCartolyticsCustomerData contains the needed data inputted from the create cartolytics customer form.
         *
         * @returns {HttpPromise} Returns newly created id of the cartolytics customer.
         */
        createCartolyticsCustomer: function createCartolyticsCustomer(createCartolyticsCustomerData) {
            let apiBase = apiUrl + 'create-cartolytics-customer';
            let data = {
                name: createCartolyticsCustomerData.companyName,
                domain: createCartolyticsCustomerData.companySubDomain,
                email: createCartolyticsCustomerData.contactEmail,
                cartolytics_customer_owner_id: createCartolyticsCustomerData.cartolytics_customer_owner_id
            };

            return $http.post(apiBase, data);
        },

        /**
         * Creates a new user with the data provided.
         *
         * @param createCartolyticsCustomerUserData data from the create new cartolytics customer form. Holds
         * the new name, email, password and cartolytics customer id. Password is empty and will go to default.
         *
         * @returns {HttpPromise} the id of the newly created user.
         */
        createCartolyticsCustomerUser: function createCartolyticsCustomerUser(createCartolyticsCustomerUserData) {
            let apiBase = apiUrl + 'create-cartolytics-customer-user';
            let data = {
                name: createCartolyticsCustomerUserData.userName,
                contact_email: createCartolyticsCustomerUserData.contactEmail,
                password: createCartolyticsCustomerUserData.userPassword,
                id: createCartolyticsCustomerUserData.id
            };
            return $http.post(apiBase, data);
        },

        /**
         * Creates a new empty job, while setting the cartolytics customer with the necessary roles to be
         * able to view the new job.
         *
         * @param createEmptyJobModuleData contains the data from the create module form. Holds the
         * cartolytics customer of the id and title of the job to be created.
         * @returns {HttpPromise} Returns the newly created job id.
         */
        createEmptyJobModule: function createEmptyJobModule(createEmptyJobModuleData) {
            let apiBase = apiUrl + 'create-job-module';
            let data = {
                blocworxCustomerID: createEmptyJobModuleData.cartolyticsCustomerID,
                title: createEmptyJobModuleData.title
            };
            return $http.post(apiBase, data);

        },

        /**
         * Creates a new instance of Cartolytics for a customer. It creates all the necessary menus, page sections and links
         * for a new Cartolytics customer.
         *
         * @param cartolyticsCustomerID - ID of the newly created Cartolytics Customer that all the menus,page sections and links.
         * @returns {HttpPromise}
         */
        createNewCartolyticsCustomerInstance: function createNewCartolyticsCustomerInstance(cartolyticsCustomerID) {
            let apiBase = apiUrl + 'create-cartolytics-customer-instance';
            let data = {
                newBlocworxCustomerID: cartolyticsCustomerID,
            };
            return $http.post(apiBase, data);
        },

        /**
         * Sets the user with all the necessary roles for the newly created instance.
         *
         * @param cartolyticsCustomerID - ID of the owner of the user.
         * @param userID - ID of the user that the roles need to be updated.
         * @param rolesArray - A string or string array of the roles needed by the user.
         * @returns {HttpPromise}
         */
        setNewCartolyticsCustomerInstanceUserRoles: function setNewCartolyticsCustomerInstanceUserRoles(cartolyticsCustomerID, userID, rolesArray) {
            let apiBase = apiUrl + 'update-cartolytics-customer-user';
            let data = {
                newBlocworxCustomerID: cartolyticsCustomerID,
                userID: userID,
                roles: rolesArray
            };
            return $http.post(apiBase, data);
        }


    }
}

ManagerServiceConfig.$inject = ['$compileProvider'];
function ManagerServiceConfig ($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
}
