import * as angular from 'angular';

'use strict';

angular.module('AuthCtrl')
    .controller('AuthController', AuthController);

AuthController.$inject = ['$auth', '$state', 'Auth', 'User', 'Data',
    '$window', '$interval', 'Configuration', '$scope', 'TwoFactorAuthentication',
    'LoginExceptions'
];

function AuthController($auth, $state, Auth, User, Data,
    $window, $interval, Configuration, $scope, TwoFactorAuthentication,
    LoginExceptions
) {

    let vm = this;

    vm.$onInit = async function () {
        try {
            let version = Configuration.getVersion();
            let subDomain = Configuration.getSubDomain();
            let country = '';
            let flag = '';

            vm.version = version
            vm.subDomain = subDomain;

            vm.versionUpdateRequiredPopUp = 'closed';
            vm.country = country;
            vm.flag = flag;
            vm.authstate = $state.current.name;
            vm.loggedOutDueToInactivity = ($state.params.inactivitylogout == 'true');
            vm.is2FARequired = false;
            vm.failedCode = false;
            vm.codeResent = false;

            await vm.compareVersion();


        } catch (e) {
            console.log(e);
        }

    }

    vm.getUsers = async function () {
        try {
            await User.getUsers();

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This is doing the logout steps
     * for a blocworx user.
     */
    vm.logoutAction = async () => {
        try
        {
            // Log out if on logout page
            if (vm.authstate == 'logout') {

                $window.localStorage.removeItem('redirect');
                vm.loggedMessage = 'Logging Out...';
                vm.token = $window.localStorage.getItem('satellizer_token');

                if (vm.token != null) {
                    await Auth.logout(vm.token);
                    $window.localStorage.removeItem('userRoles');
                    $window.localStorage.removeItem('userID');
                    $window.localStorage.removeItem('isCustomer');
                    $window.localStorage.removeItem('isSuperUser');
                    $window.localStorage.removeItem('cartolyticsCustomerShowOptions');
                    $window.localStorage.removeItem('translatedWords');
                    sessionStorage.clear();
                    $auth.logout();
                    window.location.reload();

                } else {
                    vm.loggedMessage = 'You are now logged out!';
                }
            }

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This function is triggered on the main password
     * input that is checking gor the key-press event,
     * and for the login part if we press enter (13), this
     * will be triggering the login action.
     *
     * @param e
     */
    vm.triggerAuthLogin = async function (e) {
        try {
            if (e.keyCode == 13) {
                await vm.login();
            }
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This is the main login fucntion, this will be checking a few things
     * before login, good things to know is we are able to login
     * with or without 2 factor authenticator, and for those cases please check the
     * application/views/authView.html.
     *
     * Main of the rules you will need to check for the variables into the template file
     * you will see that:
     *
     * On normal login we use those
     * is2FARequired
     * failedLogin
     * loginError
     *
     * On 2FA we use:
     * failedCode
     * is2FARequired
     * codeError
     * codeResent
     *
     */
    vm.login = async function () {
        try {
            vm.wrongDetails = false;

            let credentials = {
                email: vm.email,
                password: vm.password
            }

            // step 1 : check if the user exist
            const existUser = await TwoFactorAuthentication.existUser(vm.email);

            // Step 2 : If does not exist a user credential on the table User, must thrown an exception
            if(existUser == false){
                LoginExceptions.throwDoesNotExistUserException();
            }

            // Step 3: now the user is validated, and exist, we check for the 2FactorAuthenticator
            const is2FARequired = await TwoFactorAuthentication.isRequired(vm.email);
            if(is2FARequired) {

                // Step 3.1 = Checking if the credentials are valid
                const isValidCredentials = await TwoFactorAuthentication.validateCredentials(
                    vm.email, vm.password
                );

                // Step 3.2 = throw an exception if we have invalid credentials
                if(isValidCredentials == false){
                    LoginExceptions.throwInvalidCredentialsException();
                }

                vm.is2FARequired = true;

                // note: as this have an extra step, we do not need to have the vm.proceedWithLogin
                // as it is being doing that on verify2FA function, that is the function that validates
                // the number and over there is doing the vm.proceedWithLogin()
                await $auth.login(credentials);

            } else {

                // Normal Login Without 2 Factor Authenticator
                await $auth.login(credentials)
                await vm.procceedWithLogin();
            }

        } catch (exception) {

            console.log(exception);
            vm.loginError = Auth.getLoginError(exception);
            vm.failedLogin = true;

        } finally {
            $scope.$apply();
        }

    }

    /**
     * This will allow the user to proceed with the login action.
     */
    vm.procceedWithLogin = async function () {0
        try {
            
            let data2 = await Data.getAppSettings();

            $window.localStorage.setItem('appSettings', JSON.stringify(data2.data));
            let redirect = '/';

            if ($window.localStorage.getItem('redirect') != null && $window.localStorage.getItem('redirect') != '/') {
                redirect = $window.localStorage.getItem('redirect');
                $window.localStorage.removeItem('redirect');
            } else {
                angular.forEach(data2.data.data, function (value, index) {
                    if (value.setting_key == 'redirect') {
                        redirect = value.setting_value;
                    }
                });
            }

            $window.location.href = redirect;

        } catch (e) {
            throw e
        }
    }

    /**
     * This is the function that is checking the Two factor authentication
     * @param code
     */
    vm.verify2FA = async function (code: string) {
        try
        {
            const isValid = await TwoFactorAuthentication.verifyCode(code);
            if(!isValid){
                vm.failedCode = true;
                LoginExceptions.throwWrong2FACodeException();
            }

            // If everything is validated we can proceed with the login
            vm.is2FARequired = false;
            await vm.procceedWithLogin();

        }
        catch (exception) {
            console.log(exception);
            vm.codeError = Auth.getLoginError(exception);
        } finally {
            $scope.$apply();
        }
    }

    /**
     * This is a function that re-send the code for the
     * 2 factor authentication.
     */
    vm.resendCode = async function () {
        try {
            vm.codeResent = true;
            vm.failedCode = false;
            vm.codeError = 'Code Sent';
            await TwoFactorAuthentication.resendCode(vm.email);
        } catch (e) {
            console.log(e);
            vm.loginError = Auth.errorMessage;
        }
        finally {
            $scope.$apply();
        }
    }

    /**
     * This is verifying if the version are equal or
     * it is necessary to update the version to get
     * updates from deployments.
     */
    vm.compareVersion = async function () {
        try
        {
            await Auth.compareVersions(vm.version);
            vm.versionUpdateRequired = false;

        } catch (e) {
            console.log(e);
            vm.versionUpdateRequiredPopUp = 'open';
            vm.versionUpdateRequired = true;
            vm.versionUpdateMessage = e.data.error;
        }
    }

}
