
class scanStationCtrlObj {

    private _id: string;
    private _jobID: string;
    private _stateParams: string;
    private _dataToAdd: object;
    private _liveData: object;
    private _filesToAdd: object;
    private _submitForm: any;
    private _stateName: string;
    private _cartolyticsCustomerID: string;
    private _submittedStatus: any;
    private _entryCycleKey: any;
    private _entryCycleKeySlug: string;
    private _userSearchKey: any;
    private _returnedStationID: any;
    private _scanStationFields: any;
    private _stationDetails: any;
    private _stationName: string;
    private _showForm: any;
    private _rmaNumber: any;
    private _formceptionData: any;
    private _isInEditMode: any;

    constructor() {
        this._id;
        this._jobID;
        this._stateParams;
        this._dataToAdd = {};
        this._liveData = {};
        this._filesToAdd = {};
        this._submitForm;
        this._stateName;
        this._cartolyticsCustomerID;
        this._submittedStatus;
        this._entryCycleKey;
        this._entryCycleKeySlug;
        this._userSearchKey;
        this._returnedStationID;
        this._scanStationFields;
        this._stationDetails;
        this._stationName;
        this._showForm;
        this._rmaNumber;
        this._submitForm = false;
        this._submittedStatus = 1;
        this._entryCycleKey = 1;
        this._entryCycleKeySlug = '';
        this._returnedStationID = false;
        this._formceptionData = [];
        this._isInEditMode = false;

    }

    get formceptionData() {
        return this._formceptionData;
    }

    set formceptionData(value) {
        this._formceptionData = value;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get jobID() {
        return this._jobID;
    }

    set jobID(value) {
        this._jobID = value;
    }

    get stateParams() {
        return this._stateParams;
    }

    set stateParams(value) {
        this._stateParams = value;
    }

    get dataToAdd() {
        return this._dataToAdd;
    }

    set dataToAdd(value) {
        this._dataToAdd = value;
    }

    get liveData() {
        return this._liveData;
    }

    set liveData(value) {
        this._liveData = value;
    }

    get filesToAdd() {
        return this._filesToAdd;
    }

    set filesToAdd(value) {
        this._filesToAdd = value;
    }

    get submitForm() {
        return this._submitForm;
    }

    set submitForm(value) {
        this._submitForm = value;
    }

    get stateName() {
        return this._stateName;
    }

    set stateName(value) {
        this._stateName = value;
    }

    get cartolyticsCustomerID() {
        return this._cartolyticsCustomerID;
    }

    set cartolyticsCustomerID(value) {
        this._cartolyticsCustomerID = value;
    }

    get submittedStatus() {
        return this._submittedStatus;
    }

    set submittedStatus(value) {
        this._submittedStatus = value;
    }

    get entryCycleKey() {
        return this._entryCycleKey;
    }

    set entryCycleKey(value) {
        this._entryCycleKey = value;
    }

    get entryCycleKeySlug() {
        return this._entryCycleKeySlug;
    }

    set entryCycleKeySlug(value) {
        this._entryCycleKeySlug = value;
    }

    get userSearchKey() {
        return this._userSearchKey;
    }

    set userSearchKey(value) {
        this._userSearchKey = value;
    }

    get returnedStationID() {
        return this._returnedStationID;
    }

    set returnedStationID(value) {
        this._returnedStationID = value;
    }

    get rmaNumber() {
        return this._rmaNumber;
    }

    set rmaNumber(value) {
        this._rmaNumber = value;
    }

    get stationDetails() {
        return this._stationDetails;
    }

    set stationDetails(value) {
        this._stationDetails = value;
    }

    get stationName() {
        return this._stationName;
    }

    set stationName(value) {
        this._stationName = value;
    }

    get scanStationFields() {
        return this._scanStationFields;
    }

    set scanStationFields(value) {
        this._scanStationFields = value;
    }

    get showForm() {
        return this._showForm;
    }

    set showForm(value) {
        this._showForm = value;
    }

    get isInEditMode() {
        return this._isInEditMode;
    }

    set isInEditMode(value) {
        this._isInEditMode = value;
    }

}

export { scanStationCtrlObj };