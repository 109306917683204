import * as angular from 'angular';

'use strict';

angular.module('splitFilterModule',[])
       .filter('split', SplitFilter);

SplitFilter.$inject = ['$parse'];

function SplitFilter($parse)
{
    return function (input, delimiter) {
        if (delimiter === undefined){
            delimiter = ','
        }
        return input.split(delimiter);
    }
}



