import * as angular from 'angular';

'use strict';

angular.module('EnterDataService')
    .factory('Data', DataFactory)
    .config(EnterDataConfig)

DataFactory.$inject = ['$http', '$rootScope', 'Configuration'];

function DataFactory($http, $rootScope, Configuration) {

    let factory = this;
    let apiUrl = Configuration.getApiUrl();

    factory.$onInit = () => {
        try
        {
            factory.userSetting = {}
            factory.cartolyticsCustomerID = undefined;
            factory.staticJobID = undefined;

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This will be updating the enter data controller.
     *
     * Unfortunately I need to work this out, its related to a broadcast I am doing where I was sending information
     * to the Data Controller as some elements of the data controller are still in the scan station
     * TODO: Adrian to figure this out and possibly refactor so we can remove things in data controller on scan station
     *
     * @param updatedData
     * @param msg
     */
    factory.updateEnterDataController = (updatedData, msg) => {
        factory.updatedData = updatedData;
        $rootScope.$broadcast(msg);
    }

    /**
     * This is the saving data message broadcast.
     * I am not 100% right now what this is for
     * TODO: Adrian to figure this out and remove or refactor
     * @param msg
     */
    factory.addDataTransistion = (msg) => {
        factory.savingData = msg;
        $rootScope.$broadcast('savingDataBroadcast');
    }

    /**
     * This will load the page links.
     *
     * @param pageState
     */
    factory.getPageLinks = (pageState) => {
        let apiBase = apiUrl + 'get-page-links';
        let config = {
            params: {
                pageState: pageState
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will return what is the current Cartolytics Customer ID
     */
    factory.getCartolyticsCustomerID = async () => {

        // this will load once in the bootstrap action, so we can load only once the id
        if(factory.cartolyticsCustomerID == undefined) {
            let apiBase = apiUrl + 'get-cartolytics-customer-id';
            let config = {
                params: {}
            };

            let returnedData = await $http.get(apiBase, config);
            if(returnedData !== undefined){
                factory.cartolyticsCustomerID = returnedData.data.data;
            }
        }

        return factory.cartolyticsCustomerID;

    }

    /**
     * This will update Job Status.
     *
     * TODO: move this to a Job Service?
     *
     * @param jobID
     * @param statusUpdateTo
     */
    factory.updateJobStatus = (jobID, statusUpdateTo) => {
        let apiBase = apiUrl + 'update-job-status';
        let config = {
            params: {
                jobID: jobID,
                statusUpdateTo: statusUpdateTo
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be the action of create of a job.
     *
     * TODO: move this to a Job Service?
     *
     * @param createJobData
     */
    factory.createJob = (createJobData) => {
        let apiBase = apiUrl + 'job';
        let fd = new FormData();
        angular.forEach(createJobData, function (value, index: any) {
            fd.append(index, value);
        });
        return $http.post(apiBase,fd,{
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        });
    }

    /**
     * This will be the action to update a job.
     *
     * TODO: move this to a Job Service?
     *
     * @param jobData
     * @param jobID
     */
    factory.updateJob = (jobData, jobID) => {
        let apiBase = apiUrl + 'update-job';
        let fd = new FormData();
        angular.forEach(jobData, function (value, index: any) {
            fd.append(index, value);
        });
        fd.append('jobID', jobID);

        return $http.post(apiBase,fd,{
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        });
    }

    /**
     * This will be getting all jobs.
     *
     * TODO: move this to a Job Service
     *
     * @param status
     * @param sortKey
     * @param master
     */
    factory.getJobs = (status, sortKey, master) => {
        let apiBase = apiUrl + 'job';
        let config = {
            params: {
                status: status,
                sortKey: sortKey,
                master: master
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will get the customers and their jobs.
     *
     * TODO: move this to a Job Service
     *
     */
    factory.getCustomersJobs = () => {
        let apiBase = apiUrl + 'get-customers-jobs';
        let config = {
            params: {
                status: status
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will load all jobs of a customer.
     *
     * TODO: move this to a Job Service
     *
     * @param id
     */
    factory.getCustomerJob = (id) => {
        let apiBase = apiUrl + 'get-customer-job';
        let config = {
            params: {
                id: id
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be gatting a job by their id.
     *
     * TODO: move this to a Job Service
     *
     * @param id
     */
    factory.getJob = (id) => {
        let apiBase = apiUrl + 'job/' + id;
        let config = {
            params: {
                id: id
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will return all users of a job, by passing the JOB ID
     * you will be getting the users.
     *
     * TODO: move this to a Job Service
     *
     * @param jobID
     */
    factory.getJobUsers = (jobID) => {
        let apiBase = apiUrl + 'get-job-users';
        let config = {
            params: {
                jobID: jobID
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be adding a job to a user, by saying the job ID and
     * User ID you will be adding the job to a respective user ID.
     *
     * TODO: move this to a Job Service
     *
     * @param jobID
     * @param selectedUserID
     */
    factory.addJobUser = (jobID, selectedUserID) => {
        let apiBase = apiUrl + 'add-job-user';
        let data = {
            jobID: jobID,
            selectedUserID: selectedUserID
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will be returning the user jobs.
     *
     * TODO: move this to a Job Service
     *
     * @param sortBy
     * @param master
     * @param sortByOrder
     * @param jobTypeToFilterBy
     * @param resultLimit
     * @param currentPage
     * @param stateName
     */
    factory.getUsersJobs = (sortBy, master, sortByOrder,jobTypeToFilterBy, resultLimit, currentPage, stateName) => {
        let apiBase = apiUrl + 'get-users-jobs';
        let config = {
            params: {
                sortBy: sortBy,
                master: master,
                sortByOrder: sortByOrder,
                jobTypeToFilterBy: jobTypeToFilterBy,
                resultLimit: resultLimit,
                currentPage: currentPage,
                stateName: stateName
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will archive a Job
     *
     * TODO: move this to a Job Service
     *
     * @param jobID
     */
    factory.archiveJob = (jobID) => {
        let apiBase = apiUrl + 'archive-job';
        let config = {
            params: {
                jobID: jobID,
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will update a Job Rule
     *
     * TODO: move this to a Job Service
     *
     */
    factory.updateJobRule = (jobID, ruleSetting, value) => {
        let apiBase = apiUrl + 'update-job-rule';
        let data = {
            jobID: jobID,
            ruleSetting: ruleSetting,
            value: value
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will be getting Jobs for SOP's
     *
     * TODO: move this to a Job Service
     *
     * @param sortKey
     */
    factory.getJobsForSOPs = (sortKey) => {
        let apiBase = apiUrl + 'get-jobs-for-sops';
        let config = {
            params: {
                sortKey: sortKey
            }

        };
        return $http.get(apiBase, config);
    }

    /**
     * This will update the job Title.
     *
     * TODO: move this to a Job Service
     *
     * @param jobTitle
     * @param jobID
     */
    factory.updateJobTitle = (jobTitle, jobID) => {
        let apiBase = apiUrl + 'update-job-title';
        let data = {
            jobTitle: jobTitle,
            jobID: jobID
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will be loading a JobID by Job Slug.
     *
     * TODO: move this to a Job Service
     *
     * @param staticJobSlug
     * @param refresh
     */
    factory.getStaticJobID = async (staticJobSlug, refresh = false) => {

        if (refresh == true) {
            factory.staticJobID = undefined
        }

        if(factory.staticJobID == undefined) {
            let apiBase = apiUrl + 'get-static-job-id';
            let config = {
                params: {
                    staticJobSlug: staticJobSlug
                }
            };

            let returnedData  = await $http.get(apiBase, config);
            if(returnedData !== undefined){
                factory.staticJobID = returnedData.data.jobID;
            }
        }

        return factory.staticJobID;
    }

    /**
     * This will load all job status.
     *
     * TODO: move this to a Job Service
     *
     */
    factory.getJobStatuses = () => {
        let apiBase = apiUrl + 'get-job-statuses';
        let config = {
            params: {}
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will get station and Job by passing a special key as
     * main parameter.
     *
     * TODO: move this to a Job Service
     *
     * @param specialKey
     */
    factory.getStationAndJobBySpecialKey = (specialKey) => {
        let apiBase = apiUrl + 'get-station-and-job-by-special-key';
        let config = {
            params: {
                specialKey: specialKey
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will get the Independant Station by passing it's slug.
     *
     * TODO: move this to a Job Service
     * TODO: Check the independant, maybe should change to independent ? (typo)
     * @param independantStationSlug
     */
    factory.getIndependantStationJobIDandStationID = (independantStationSlug) => {
        let apiBase = apiUrl + 'get-independant-station-job-id-station-id';
        let config = {
            params: {
                independantStationSlug: independantStationSlug
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will create a job status.
     *
     * TODO: move this to a Job Service
     *
     * @param newStatus
     * @param instanceID
     * @param excludeStatus
     * @param defaultStatus
     */
    factory.createJobStatus = (newStatus, instanceID, excludeStatus, defaultStatus) => {
        let apiBase = apiUrl + 'create-job-status';
        let data = {
            title: newStatus,
            instanceID: instanceID,
            defaultStatus: defaultStatus,
            excludeStatus: excludeStatus
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will be deleting a status from a JOB.
     *
     * TODO: move this to a Job Service
     *
     * @param deleteJobStatus
     */
    factory.deleteJobStatus = (deleteJobStatus) => {
        let apiBase = apiUrl + 'delete-job-status';
        let data = {
            statusToDelete: deleteJobStatus,

        };
        return $http.post(apiBase, data);
    }

    /**
     * This will convert a Job into a Module.
     *
     * TODO: move this to a Job Service
     *
     * @param jobID
     */
    factory.convertJobToModule = (jobID) => {
        let apiBase = apiUrl + 'convert-job-to-module';
        let data = {
            jobID: jobID
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will be getting Station with File Fields.
     *
     * TODO: move this to a Scan Station Service
     *
     */
    factory.getStationsWithFileFields = () => {
        let apiBase = apiUrl + 'get-stations-with-file-fields';
        let config = {
            params: {}
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting Scan Station Counts.
     *
     * TODO: move this to a Scan Station Service
     *
     * @param jobID
     */
    factory.getStationCounts = (jobID) => {
        let apiBase = apiUrl + 'get-station-counts';
        let config = {
            params: {
                jobID: jobID
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting Scan Station Image.
     *
     * TODO: move this to a Scan Station Service
     *
     * @param subDomain
     * @param jobID
     * @param stationID
     * @param fileName
     */
    factory.getStationImage = (subDomain, jobID, stationID, fileName) => {
        let apiBase = apiUrl + 'get-station-image/' + subDomain + '/' + jobID
            + '/' + stationID + '/' + fileName;
        let config = {
            responseType: 'blob'
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be updating a Scan Station Section Name.
     *
     * TODO: move this to a Scan Station Service
     *
     * @param newName
     * @param sectionID
     */
    factory.updateSectionName = (newName, sectionID) => {
        let apiBase = apiUrl + 'update-station-section-name';
        let data = {
            newName: newName,
            sectionID: sectionID
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will be import Scan Station from another module
     *
     * TODO: move this to a Scan Station Service
     *
     * @param originalModuleId
     * @param newModuleId
     * @param replicateFieldRoles
     * @param replicateUserAccess
     * @param replicateRules
     */
    factory.importAllScanStationsFromAnotherModule = (originalModuleId, newModuleId, replicateFieldRoles, replicateUserAccess, replicateRules) => {
        let apiBase = apiUrl + 'import-all-scan-stations-from-another-module';
        let config = {
            params: {
                originalModuleId: originalModuleId,
                newModuleId: newModuleId,
                replicateFieldRoles: replicateFieldRoles,
                replicateUserAccess: replicateUserAccess,
                replicateRules
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will load scan stations by the JOB ID provided.
     *
     * TODO: move this to a Scan Station Service
     *
     * @param jobID
     */
    factory.getScanStations = (jobID) => {
        let apiBase = apiUrl + 'get-scan-stations';
        let config = {
            params: {
                jobID: jobID
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be adding a new scan station.
     *
     * TODO: move this to a Scan Station Service
     *
     * @param jobID
     * @param scanStationName
     * @param newScanStationSectionID
     * @param newScanStationIcon
     */
    factory.addNewScanStation = (jobID, scanStationName, newScanStationSectionID, newScanStationIcon) => {
        let apiBase = apiUrl + 'add-new-scan-station';
        let data = {
            jobID: jobID,
            scanStationName: scanStationName,
            newScanStationSectionID: newScanStationSectionID,
            newScanStationIcon: newScanStationIcon
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will update the scan station Order.
     *
     * TODO: move this to a Scan Station Service
     *
     * @param stationID
     * @param direction
     */
    factory.updateScanStationOrderID = (stationID,
                                        direction) => {
        let apiBase = apiUrl + 'update-scan-station-order-id';
        let data = {
            stationID: stationID,
            direction: direction
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will be adding a new Scan Station Section.
     *
     * TODO: move this to a Scan Station Service
     *
     * @param jobID
     * @param sectionName
     */
    factory.addNewScanStationSection = (jobID,
                                        sectionName) => {
        let apiBase = apiUrl + 'add-new-scan-station-section';
        let data = {
            jobID: jobID,
            sectionName: sectionName
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will be deleting a scan station.
     *
     * TODO: move this to a Scan Station Service
     *
     * @param scanStationID
     */
    factory.deleteScanStation = (scanStationID) => {
        let apiBase = apiUrl + 'delete-scan-station';
        let config = {
            params: {
                scanStationID: scanStationID
            }
        };
        return $http.delete(apiBase, config);
    }

    /**
     * This will be deleting a Section of a Scan Station.
     *
     * TODO: move this to a Scan Station Service
     *
     * @param sectionID
     */
    factory.deleteScanStationSection = (sectionID) => {
        let apiBase = apiUrl + 'delete-scan-station-section';
        let config = {
            params: {
                sectionID: sectionID
            }
        };
        return $http.delete(apiBase, config);
    }

    /**
     * This will be generating Station from another Scan Station ID.
     *
     * TODO: move this to a Station Service
     *
     * @param originalStationID
     * @param newModuleId
     * @param replicateFieldRoles
     * @param replicateUserAccess
     * @param replicateRules
     */
    factory.generateStationFromAnotherStationID = (originalStationID, newModuleId, replicateFieldRoles, replicateUserAccess, replicateRules) => {
        let apiBase = apiUrl + 'generate-station-from-another-station-id';
        let config = {
            params: {
                originalStationID: originalStationID,
                newModuleId: newModuleId,
                replicateFieldRoles: replicateFieldRoles,
                replicateUserAccess: replicateUserAccess,
                replicateRules
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be updating the user settings.
     *
     * TODO: move this to a User Service
     *
     * @param key
     * @param value
     */
    factory.updateUserSetting = (key, value) => {
        let apiBase = apiUrl + 'update-user-setting';
        let data = {
            key: key,
            value: value
        };

        // this will be setting to the service userSettings
        factory.userSetting = $http.post(apiBase, data);

        return factory.userSetting;
    }

    /**
     * This will be getting a user setting.
     *
     * TODO: move this to a User Service
     *
     * @param key
     */
    factory.getUserSetting = (key) => {
        let apiBase = apiUrl + 'get-user-setting';
        let config = {
            params: {
                key: key
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting all users.
     *
     * TODO: move this to a User Service
     *
     */
    factory.getAllUsers = () => {
        let apiBase = apiUrl + 'manage-users';
        let config = {};
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting Anti Static User Logs.
     *
     * TODO: move this to a User Service
     *
     */
    factory.getAntiStaticUserLogs = () => {
        let apiBase = apiUrl + 'get-anti-static-user-logs';
        let config = {};
        return $http.get(apiBase, config);
    }

    /**
     * This will return the all the User Scan Stations.
     *
     * TODO: move this to a User Service
     *
     * @param jobID
     * @param metaDataFilters
     * @param stateName
     */
    factory.getUsersStations = (jobID, metaDataFilters, stateName) => {
        let apiBase = apiUrl + 'get-users-stations';
        let config = {
            params: {
                jobID: jobID,
                metaDataFilters: metaDataFilters,
                stateName: stateName
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting the User Signed Data.
     *
     * TODO: move this to a User Service
     *
     * @param jobID
     * @param itemSigned
     */
    factory.getUserSignedData = (jobID, itemSigned) => {
        let apiBase = apiUrl + 'get-user-signed-data';
        let config = {
            params: {
                jobID: jobID,
                itemSigned: itemSigned
            }
        };
        return $http.get(apiBase, config);
    }


    /**
     * This will be getting the user Signed Report
     *
     * TODO: move this to a Report Service
     *
     * @param jobID
     */
    factory.getUserSignedReport = (jobID) => {
        let apiBase = apiUrl + 'get-user-signed-report';
        let config = {
            responseType: 'blob',
            params: {
                jobID: jobID
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting the data for a report.
     *
     * TODO: move this to a Report Service
     *
     * @param stationID
     * @param requestedReportData
     * @param fieldsToReport
     * @param responseType
     * @param infoPage
     * @param dataFilteringData
     */
    factory.getDataReport = (stationID, requestedReportData,
                             fieldsToReport, responseType, infoPage, dataFilteringData) => {
        let apiBase = apiUrl + 'get-data-report';
        let config = {
            responseType: responseType,
            params: {
                stationID: stationID,
                requestedReportData: requestedReportData,
                fieldsToReport: fieldsToReport,
                responseType: responseType,
                infoPage: infoPage,
                dataFilteringData
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting a report by Selected Rows param.
     *
     * TODO: move this to a Report Service
     *
     * @param selectedRows
     */
    factory.getReportByStationDataIDs = (selectedRows) => {
        let apiBase = apiUrl + 'get-report-by-station-data-ids';
        let config = {
            responseType: 'blob',
            params: {
                selectedRows: selectedRows
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be generating Anecto Scan Station Report.
     *
     * TODO: move this to a Report Service
     *
     * @param stationID
     * @param jobID
     */
    factory.generateAnectoStationReport = (stationID, jobID) => {
        let apiBase = apiUrl + 'generate-anecto-station-report';
        let config = {
            responseType: 'blob',
            params: {
                stationID: stationID,
                jobID: jobID
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting customer data for a report.
     *
     * TODO: move this to a Report Service
     *
     * @param stationID
     * @param requestedReportData
     * @param fieldsToReport
     */
    factory.getCustomerDataReport = (stationID, requestedReportData, fieldsToReport) => {
        let apiBase = apiUrl + 'get-customer-data-report';
        let config = {
            responseType: 'blob',
            params: {
                stationID: stationID,
                requestedReportData: requestedReportData,
                fieldsToReport: fieldsToReport,
                responseType: 'blob',
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting the Summary to count for a report.
     *
     * TODO: move this to a Report Service
     *
     * @param stationID
     * @param requestedReportData
     * @param fieldsToReport
     */
    factory.getSummaryCountReport = (stationID, requestedReportData, fieldsToReport) => {
        let apiBase = apiUrl + 'get-summary-count-report';
        let config = {
            responseType: 'blob',
            params: {
                stationID: stationID,
                requestedReportData: requestedReportData,
                fieldsToReport: fieldsToReport
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting a customer summary for a report.
     *
     * TODO: move this to a Report Service
     *
     * @param stationID
     * @param requestedReportData
     * @param fieldsToReport
     */
    factory.getCustomerSummaryCountReport = (stationID, requestedReportData, fieldsToReport) => {
        let apiBase = apiUrl + 'get-customer-summary-count-report';
        let config = {
            responseType: 'blob',
            params: {
                stationID: stationID,
                requestedReportData: requestedReportData,
                fieldsToReport: fieldsToReport
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting an Anti Static report.
     *
     * TODO: move this to a Report Service
     *
     * @param requestedReportData
     */
    factory.getAntiStaticReport = (requestedReportData) => {
        let apiBase = apiUrl + 'get-anti-static-report';
        let config = {
            responseType: 'blob',
            params: {
                requestedReportData: requestedReportData
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting the customer Anti Static Report.
     *
     * TODO: move this to a Report Service
     *
     * @param requestedReportData
     */
    factory.getCustomerAntiStaticReport = (requestedReportData) => {
        let apiBase = apiUrl + 'get-customer-anti-static-report';
        let config = {
            responseType: 'blob',
            params: {
                requestedReportData: requestedReportData
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting Signed In and Out for
     * a report.
     *
     * TODO: move this to a Report Service
     *
     * @param requestedReportData
     */
    factory.getSignedInOutReport = (requestedReportData) => {
        let apiBase = apiUrl + 'get-signed-in-out-report';
        let config = {
            responseType: 'blob',
            params: {
                requestedReportData: requestedReportData
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting a general Audit Report.
     *
     * TODO: move this to a Report Service
     *
     * @param requestedReportData
     */
    factory.getGeneralAuditReport = (requestedReportData) => {
        let apiBase = apiUrl + 'get-general-audit-report';
        let config = {
            responseType: 'blob',
            params: {
                requestedReportData: requestedReportData
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will getting Job Details Report.
     *
     * TODO: move this to a Report Service
     *
     * @param jobID
     */
    factory.getJobDetailsReport = (jobID) => {
        let apiBase = apiUrl + 'get-job-details-report';
        let config = {
            responseType: 'blob',
            params: {
                jobID: jobID
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be adding a new Automated Report.
     *
     * TODO: move this to a Report Service
     *
     * @param newAutomatedReport
     */
    factory.addAutomatedReport = (newAutomatedReport) => {
        let apiBase = apiUrl + 'add-automated-report';
        let data = {
            newAutomatedReport: newAutomatedReport
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will update an Automated Report.
     *
     * TODO: move this to a Report Service
     *
     * @param reportToEdit
     */
    factory.updateAutomatedReport = (reportToEdit) => {
        let apiBase = apiUrl + 'update-automated-report';
        let data = {
            reportToEdit: reportToEdit
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will be getting Automated Report for a jobID.
     *
     * TODO: move this to a Report Service
     *
     * @param jobID
     */
    factory.getAutomatedReports = (jobID) => {
        let apiBase = apiUrl + 'get-automated-reports';
        let config = {
            params: {
                jobID: jobID
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will delete a Automated Report by passing it's ID.
     *
     * TODO: move this to a Report Service
     *
     * @param id
     */
    factory.deleteAutomatedReport = (id) => {
        let apiBase = apiUrl + 'delete-automated-report';
        let config = {
            params: {
                id: id
            }
        };
        return $http.delete(apiBase, config);
    }

    /**
     * This will be getting customer show options data.
     *
     * TODO: move this to a Customer Service
     *
     */
    factory.getCartolyticsCustomerShowOptions = () => {
        let apiBase = apiUrl + 'get-cartolytics-customer-show-options';
        let config = {};
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting all customers.
     *
     * TODO: move this to a Customer Service
     */
    factory.getCustomers = () => {
        let apiBase = apiUrl + 'customer';
        let config = {
            params: {}
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting by passing customer ID all the User
     * data.
     *
     * TODO: move this to a Customer Service
     *
     * @param customerID
     */
    factory.getCustomerData = (customerID) => {
        let apiBase = apiUrl + 'get-customer-data';
        let config = {
            params: {
                customerID: customerID
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be adding a new customer.
     *
     * TODO: move this to a Customer Service
     *
     * @param customerName
     */
    factory.addCustomer = (customerName) => {
        let apiBase = apiUrl + 'customer';
        let config = {
            customerName: customerName
        };
        return $http.post(apiBase, config);
    }

    /**
     * This will be deleting a customer by passing
     * it CustomerID.
     *
     * TODO: move this to a Customer Service
     *
     * @param customerID
     */
    factory.deleteCustomer = (customerID) => {
        let apiBase = apiUrl + 'delete-customer';
        let config = {
            params: {
                customerID: customerID
            }
        };
        return $http.delete(apiBase, config);
    }

    /**
     * This will be getting the load more data.
     *
     * TODO: move this to a Customer Service
     *
     * @param scanStationID
     * @param quantity
     */
    factory.customerLoadMoreData = (scanStationID, quantity) => {
        let apiBase = apiUrl + 'customer-load-more-data';
        let config = {
            params: {
                scanStationID: scanStationID,
                quantity: quantity
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting a Station Image for the current Customer.
     *
     * TODO: move this to a Customer Service
     *
     * @param subDomain
     * @param jobID
     * @param stationID
     * @param fileName
     */
    factory.getStationImageForCustomer = (subDomain, jobID, stationID, fileName) => {
        let apiBase = apiUrl + 'get-station-image-for-customer/' + subDomain
            + '/' + jobID + '/' + stationID + '/' + fileName;
        let config = {
            responseType: 'blob'
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be updating a customer Details.
     *
     * TODO: move this to a Customer Service
     *
     * @param customerID
     * @param customerDetails
     */
    factory.updateCustomerDetails = (customerID, customerDetails) => {
        let apiBase = apiUrl + 'update-customer-details';
        let data = {
            customerID: customerID,
            customerDetails: customerDetails
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will load a customer Scan Stations by the JOB ID.
     *
     * TODO: move this to a Customer Service
     *
     * @param jobID
     */
    factory.getCustomerScanStations = (jobID) => {
        let apiBase = apiUrl + 'get-customer-scan-stations';
        let config = {
            params: {
                jobID: jobID
            }
        };
        return $http.get(apiBase, config);

    }

    /**
     * This will be getting Scan Station Counts for a Customer, by
     * passing the JOB ID.
     *
     * TODO: move this to a Customer Service
     *
     * @param jobID
     */
    factory.getStationCountsForCustomer = (jobID) => {
        let apiBase = apiUrl + 'get-station-counts-for-customer';
        let config = {
            params: {
                jobID: jobID
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting SOP Sign Off.
     *
     * TODO: move this to a SOP Service
     *
     * @param jobID
     */
    factory.getSOPSignOffs = (jobID) => {
        let apiBase = apiUrl + 'get-sop-sign-offs';
        let config = {
            params: {
                jobID: jobID
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will open a SOP File.
     *
     * TODO: move this to a SOP Service
     *
     * @param subDomain
     * @param jobID
     * @param fileName
     */
    factory.openSOPFile = (subDomain, jobID, fileName) => {
        let apiBase = apiUrl + 'get-file/' + subDomain + '/' + jobID
            + '/sops/' + fileName;
        let config = {
            responseType: 'blob'
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will upload SOP Page.
     *
     * TODO: move this to a SOP Service
     *
     * @param pageTitle
     * @param file
     * @param jobID
     */
    factory.uploadSOPPage = (pageTitle, file, jobID) => {

        let fd = new FormData();

        fd.append('sop', file);
        fd.append('pageTitle', pageTitle);
        fd.append('jobID', jobID);
        fd.append('file', file);

        let apiBase = apiUrl + 'upload-sop-page';
        return $http.post(apiBase, fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        });
    }

    /**
     * This will be getting Uploaded SOP Files.
     *
     * TODO: move this to a SOP Service
     *
     * @param jobID
     */
    factory.getUploadedSOPFiles = (jobID) => {
        let apiBase = apiUrl + 'get-uploaded-sop-files';
        let config = {
            params: {
                jobID: jobID
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be deleting a SOP File by
     * passing it's ID.
     *
     * TODO: move this to a SOP Service
     *
     * @param id
     */
    factory.deleteSOPFile = (id) => {
        let apiBase = apiUrl + 'delete-sop-file';
        let config = {
            params: {
                id: id
            }
        };
        return $http.delete(apiBase, config);
    }

    /**
     * This will be signing off a SOP.
     *
     * @param signOffType
     * @param jobID
     * @param SOPID
     * @param userID
     * @param userName
     * @param password
     * @param digitalSignature
     */
    factory.signOffSOP = (signOffType, jobID, SOPID, userID, userName, password, digitalSignature) => {
        let apiBase = apiUrl + 'sign-off-sop';
        let data = {
            signOffType: signOffType,
            jobID: jobID,
            SOPID: SOPID,
            userID: userID,
            userName: userName,
            password: password,
            digitalSignature: digitalSignature

        };
        return $http.post(apiBase, data);
    }

    /**
     * This will getting all RMA's.
     *
     * TODO: move this to a RMA Service
     *
     * @param searchTerm
     * @param offSet
     */
    factory.getRMAs = (searchTerm, offSet) => {
        let apiBase = apiUrl + 'get-rmas';
        let config = {
            params: {
                searchTerm: searchTerm,
                offSet: offSet
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be creating a RMA Request.
     *
     * TODO: move this to a RMA Service
     *
     * @param requestRmaData
     */
    factory.createRmaRequest = (requestRmaData) => {
        let fd = new FormData();

        fd.append('customerID', requestRmaData.customerID);
        fd.append('name', requestRmaData.name);
        fd.append('number', requestRmaData.number);
        fd.append('email', requestRmaData.email);
        fd.append('anectoAccountOwner', requestRmaData.anectoAccountOwner);
        fd.append('poNumber', requestRmaData.poNumber);
        fd.append('poBackUpDocument', requestRmaData.poBackUpDocument);
        fd.append('submittedStatus', requestRmaData.submittedStatus);

        // Other files
        fd.append('otherFilesCount', requestRmaData.otherFiles.length);
        for (let i = 0; i < requestRmaData.otherFiles.length; i++) {
            fd.append('otherFiles_' + (i + 1),
                requestRmaData.otherFiles[i].path);
        }

        fd.append('units', JSON.stringify(requestRmaData.units));
        fd.append('weightsMeasurements', requestRmaData.weightsMeasurements);
        fd.append('specificRequests', requestRmaData.specificRequests);
        fd.append('unitTotalPrice', requestRmaData.unitTotalPrice);

        fd.append('failureFilesCount', requestRmaData.failures.length);
        for (let i = 0; i < requestRmaData.failures.length; i++) {
            fd.append('failureFiles_' + (i + 1),
                requestRmaData.failures[i].path);
        }
        fd.append('failures', JSON.stringify(requestRmaData.failures));
        fd.append('collectionRequired', requestRmaData.collectionRequired);
        fd.append('collectionAddress', requestRmaData.collectionAddress);

        let apiBase = apiUrl + 'create-rma-reqest';
        return $http.post(apiBase, fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        });
    }

    /**
     * This will be saving an RMA Admin Files.
     *
     * TODO: move this to a RMA Service
     *
     * @param rmaId
     * @param rmaAdminFiles
     */
    factory.saveRmaAdminFiles = (rmaId, rmaAdminFiles) => {
        let fd = new FormData();
        fd.append('filesCount', rmaAdminFiles.length);
        for (let i = 0; i < rmaAdminFiles.length; i++) {
            fd.append('file_' + (i + 1), rmaAdminFiles[i].path);
        }
        let apiBase = apiUrl + 'save-rma-admin-files/' + rmaId;
        return $http.post(apiBase, fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        });
    }

    /**
     * This will be updating RMA Details.
     *
     * TODO: move this to a RMA Service
     *
     * @param rmaDetailsToUpdate
     */
    factory.updateRMADetails = (rmaDetailsToUpdate) => {
        let apiBase = apiUrl + 'update-rma-details';
        let data = {
            rmaDetailsToUpdate: rmaDetailsToUpdate
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will be getting all Saved RMA's.
     *
     * TODO: move this to a RMA Service
     *
     */
    factory.getSavedRMA = () => {
        let apiBase = apiUrl + 'get-saved-rma';
        let config = {};
        return $http.get(apiBase, config);
    }

    /**
     * This will be deleting a RMA.
     *
     * TODO: move this to a RMA Service
     *
     * @param rmaNumber
     */
    factory.deleteRMA = (rmaNumber) => {
        let apiBase = apiUrl + 'delete-rma';
        let config = {
            params: {
                rmaNumber: rmaNumber,
            }
        };
        return $http.delete(apiBase, config);
    }

    /**
     * This will be getting the Default Language.
     *
     * TODO: move this to a Language Service
     *
     */
    factory.getDefaultLanguage = async () => {
        let apiBase = apiUrl + 'get-default-language';
        let config = {};
        let defaultLanguage = {};

        let languageData = await $http.get(apiBase, config);
        if (languageData.data.data.length > 0 ) {
            defaultLanguage = languageData.data.data[0];
        }

        return defaultLanguage;

    }

    /**
     * This will be getting Language Settings.
     *
     * TODO: move this to a Language Service
     *
     * @param jobID
     */
    factory.getLanguageSettings = (jobID) => {
        let apiBase = apiUrl + 'get-language-settings';
        let config = {
            params: {
                jobID: jobID
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be updating Language Settings.
     *
     * TODO: move this to a Language Service
     *
     * @param jobID
     * @param localLanguageOfStaff
     * @param SOPTranslated
     * @param SOPUpdateRequired
     */
    factory.updateLanguageSettings = (jobID, localLanguageOfStaff, SOPTranslated, SOPUpdateRequired) => {
        let apiBase = apiUrl + 'update-language-settings';
        let data = {
            jobID: jobID,
            localLanguageOfStaff: localLanguageOfStaff,
            SOPTranslated: SOPTranslated,
            SOPUpdateRequired: SOPUpdateRequired
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will be updating a specific language setting.
     *
     * TODO: move this to a Language Service
     *
     * @param languageSetting
     */
    factory.updateLanguageSetting = (languageSetting, status) => {

        let apiBase = apiUrl + 'update-language-setting';
        let data = {
            languageSetting: languageSetting,
            status: status
        };
        return $http.post(apiBase, data);
    }


    /**
     * This will be adding an Alert.
     *
     * TODO: move this to a Alert Service
     *
     * @param alertData
     */
    factory.addAlert = (alertData) => {
        let apiBase = apiUrl + 'add-alert';
        let data = {
            alertData: alertData
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will be updating an Alert.
     *
     * TODO: move this to a Alert Service
     *
     * @param id
     * @param alertDataToUpdate
     */
    factory.updateAlert = (id, alertDataToUpdate) => {
        let apiBase = apiUrl + 'update-alert';
        let data = {
            id: id,
            alertDataToUpdate: alertDataToUpdate
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will be deleting an Alert.
     *
     * TODO: move this to a Alert Service
     *
     * @param id
     */
    factory.deleteAlert = (id) => {
        let apiBase = apiUrl + 'delete-alert';
        let config = {
            params: {
                id: id
            }
        };
        return $http.delete(apiBase, config);
    }

    /**
     * This will get an Alert data by passing it's ID.
     *
     * TODO: move this to a Alert Service
     *
     * @param id
     */
    factory.getAlertData = (id) => {
        let apiBase = apiUrl + 'get-alert-data';
        let config = {
            params: {
                id: id
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting all Alerts.
     *
     * TODO: move this to a Alert Service
     *
     */
    factory.getAlerts = () => {
        let apiBase = apiUrl + 'get-alerts';
        let config = {};
        return $http.get(apiBase, config);
    }


    /**
     * This will be getting Digital Signatures.
     *
     * TODO: move this to a Digital Signature Service
     *
     * @param jobID
     * @param signOffItem
     */
    factory.getDigitalSignatures = (jobID, signOffItem) => {
        let apiBase = apiUrl + 'get-digital-signatures';
        let config = {
            params: {
                jobID: jobID,
                signOffItem: signOffItem
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting the preview of a Digital Signature.
     *
     * TODO: move this to a Digital Signature Service
     *
     * @param userID
     */
    factory.getDigitalSignaturePreview = (userID) => {
        let apiBase = apiUrl + 'get-digital-signature-preview';
        let config = {
            params: {
                userID: userID
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be adding a new Digital Signature.
     *
     * TODO: move this to a Digital Signature Service
     *
     * @param jobID
     * @param userID
     * @param digitalSignature
     * @param signOffItem
     */
    factory.addDigitalSignature = (jobID, userID,
                                   digitalSignature, signOffItem) => {
        let apiBase = apiUrl + 'add-digital-signature';
        let data = {
            jobID: jobID,
            userID: userID,
            digitalSignature: digitalSignature,
            signOffItem: signOffItem
        };
        return $http.post(apiBase, data);
    }


    /**
     * This will be getting units with Customer Prices
     * @param customerID
     */
    factory.getUnitsWithCustomerPrices = (customerID) => {
        let apiBase = apiUrl + 'get-units-with-customer-prices';
        let config = {
            params: {
                customerID: customerID
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be uploading a new logo.
     *
     * @param logoFile
     */
    factory.uploadLogo = (logoFile) => {
        let fd = new FormData();

        fd.append('file', logoFile);
        let apiBase = apiUrl + 'upload-logo';
        return $http.post(apiBase, fd,{
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        });
    }


    /**
     * This will reordering sections.
     *
     * @param direction
     * @param sectionId
     * @param jobId
     */
    factory.reorderSections = (direction, sectionId, jobId) => {
        let apiBase = apiUrl + 'reorder-sections';
        let data = {
            direction: direction,
            sectionId: sectionId,
            jobId: jobId
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will be getting the TRaining Record Sign Offs.
     *
     * @param jobID
     */
    factory.getTrainingRecordSignOffs = (jobID) => {
        let apiBase = apiUrl + 'get-training-record-sign-offs';
        let config = {
            params: {
                jobID: jobID
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be Signing Out a user.
     *
     * @param userID
     * @param signInPassword
     * @param signInOutStatus
     */
    factory.signInOut = (userID, signInPassword, signInOutStatus) => {
        let apiBase = apiUrl + 'sign-in-out';
        let data = {
            userID: userID,
            signInPassword: signInPassword,
            signInOutStatus: signInOutStatus
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will log Anti Static Entry.
     *
     * @param userID
     * @param signInPassword
     */
    factory.logAntiStaticEntry = (userID, signInPassword) => {
        let apiBase = apiUrl + 'log-in-static-entry';
        let data = {
            userID: userID,
            signInPassword: signInPassword
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will be getting Data for File Manager.
     *
     * @param scanStationID
     */
    factory.getDataForFileManager = (scanStationID) => {
        let apiBase = apiUrl + 'get-data-for-file-manager';
        let config = {
            params: {
                scanStationID: scanStationID
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will Sign an Item.
     *
     * @param jobID
     * @param userID
     * @param password
     * @param itemSigned
     */
    factory.signItem = (jobID, userID, password, itemSigned) => {
        let apiBase = apiUrl + 'sign-item';
        let data = {
            jobID: jobID,
            userID: userID,
            password: password,
            itemSigned: itemSigned

        };
        return $http.post(apiBase, data);
    }

    /**
     * This will remove the Template.
     *
     * @param jobID
     * @param templateStatus
     */
    factory.addRemoveTemplate = (jobID, templateStatus) => {
        let apiBase = apiUrl + 'add-remove-template';
        let config = {
            params: {
                jobID: jobID,
                templateStatus: templateStatus
            }
        };
        return $http.get(apiBase, config);
    }


    /**
     * This will get the lookup data.
     *
     * @param dataToLookUp
     * @param jobToLookUp
     */
    factory.lookUpData = (dataToLookUp, jobToLookUp, filterSlugToFilterBy, specificSearch = false) => {
        let apiBase = apiUrl + 'look-up-data';
        let config = {
            params: {
                dataToLookUp: dataToLookUp,
                jobToLookUp: jobToLookUp,
                filterSlugToFilterBy: filterSlugToFilterBy,
                specificSearch: specificSearch,
            }
        };

        return $http.get(apiBase, config);
    }

    /**
     * This will Lookup Data Borg Warner
     *
     * BORG WARNER EXCEPTION
     *
     * @param dataToLookUp
     * @param jobToLookUp
     * @param lookUpSchillerData
     */
    factory.lookUpDataBW = (dataToLookUp, jobToLookUp, lookUpSchillerData) => {
        let apiBase = apiUrl + 'look-up-data-bw';
        let config = {
            params: {
                dataToLookUp: dataToLookUp,
                jobToLookUp: jobToLookUp,
                lookUpSchillerData: lookUpSchillerData
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This function updates a data record from a bloc after changing the values in Manage Data-> Data Look Up/Edit
     *
     * @param entryID
     * @param newData
     * @param stationID
     */
    factory.updateData = (entryID, newData, stationID) => {
        let apiBase = apiUrl + 'update-data';
        let config = {
            params: {
                entryID: entryID,
                newData: newData,
                stationID: stationID
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This function deletes a data record from a bloc after deleting it Manage Data-> Data Look Up/Edit

     * @param entryID
     */
    factory.deleteData = (entryID) => {
        let apiBase = apiUrl + 'delete-data';
        let config = {
            params: {
                entryID: entryID,
            }
        };
        return $http.delete(apiBase, config);
    }

    /**
     * This will be generating Uploded Excel File Template.
     *
     * @param stationId
     * @param scanStationFieldSlugs
     */
    factory.generateUploadExcelFileTemplate = (stationId, scanStationFieldSlugs) => {
        let apiBase = apiUrl + 'generate-upload-excel-file-template';
        let config = {
            responseType: 'blob',
            params: {
                scanStationFieldSlugs: scanStationFieldSlugs,
                stationId: stationId
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting the page words.
     *
     * @param page
     */
    factory.getPageWords = (page) => {
        let apiBase = apiUrl + 'get-page-words';
        let config = {
            params: {
                page: page
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting Alternative State Name.
     *
     * @param stateName
     */
    factory.getAlternativeStateName = (stateName) => {
        let apiBase = apiUrl + 'get-alternative-state-name';
        let config = {
            params: {
                stateName: stateName
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be adding a general Audit Data.
     *
     * @param auditData
     */
    factory.addGeneralAuditData = (auditData) => {
        let apiBase = apiUrl + 'add-general-audit-data';
        let data = {
            auditData: auditData
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will be loading a General Audit Data
     */
    factory.getGeneralAuditData = () => {
        let apiBase = apiUrl + 'get-general-audit-data';
        let config = {
            params: {}
        };
        return $http.get(apiBase, config);
    }

    /**
     * This Downloads selected file from a legacy feature we have called file manager
     * We may remove this in the future, it is available at http://demo.blocworx.local/manage-data/file-manager
     * There is no link to it as its not being maintained and only used by one customer (and perhaps they are not using it)
     *
     * @param subDomain
     * @param selectedFiles
     * @param stationID
     */
    factory.downloadSelectedFiles = (subDomain, selectedFiles, stationID) => {
        let apiBase = apiUrl + 'download-selected-files/' + subDomain;
        let config = {
            responseType: 'blob'
        };
        let data = {
            selectedFiles: selectedFiles,
            stationID: stationID
        }

        return $http.post(apiBase, data, config);
    }

    /**
     * This generates counts of data and displays them on a now legacy feature called Live Data Display
     * http://demo.blocworx.local/manage-data/live-data-display
     * We may remove this feature as we slowly we have moved all functionality to blocs.
     *
     * @param liveDisplayData
     */
    factory.generateLiveDisplay = (liveDisplayData) => {
        let apiBase = apiUrl + 'generate-live-display';
        let config = {
            params: {
                liveDisplayData: liveDisplayData
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This function its more related to the scan station and gets filtered data (for loads of things like dropdowns, searches, filters)
     * The reason it is here is because it was originally created for another page which is no longer being used
     * TODO: This can be refactored and moved to scanStation service, please note its a major important function always being used.
     *
     * @param dataFilteringData
     * @param fieldIdOrFieldSlug
     */
    factory.getDataForDataFiltering = (dataFilteringData, fieldIdOrFieldSlug) => {

        let apiBase = apiUrl + 'get-data-for-data-filtering';
        let config = {
            params: {
                dataFilteringData: dataFilteringData,
                resultLimit: dataFilteringData.resultLimit,
                fieldIdOrFieldSlug: fieldIdOrFieldSlug
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will be loading the Result Box Data.
     *
     * @param dataFilteringData
     * @param fieldIdOrFieldSlug
     */
    factory.loadResultBoxData = async (dataFilteringData, fieldIdOrFieldSlug) => {

        let resultBoxData = {
            data : [],
        };
        let apiBase = apiUrl + 'get-data-for-data-filtering';
        let config = {
            params: {
                dataFilteringData: dataFilteringData,
                resultLimit: dataFilteringData.resultLimit,
                fieldIdOrFieldSlug: fieldIdOrFieldSlug
            }
        };

        let returnedData = await $http.get(apiBase, config);

        if(returnedData !== undefined && returnedData.data !== undefined && returnedData.data.data !== undefined) {
            let data = returnedData.data.data;
            let arrayData = [];

            for (let index = 0; index < data.count; index++) {
                arrayData = [...arrayData, data[index]];
                delete data[index]
            }
            resultBoxData = data;
            resultBoxData.data = arrayData;
        }

        return resultBoxData;
    }

    /**
     * This saves templates for a now legacy feature called Live Data Display
     * http://demo.blocworx.local/manage-data/live-data-display
     * We may remove this feature as we slowly we have moved all functionality to blocs.
     *
     *
     * @param dataCriteria
     */
    factory.storeDataDisplayTemplate = (dataCriteria) => {
        let apiBase = apiUrl + 'store-data-display-template';
        return $http.post(apiBase, dataCriteria);
    }

    /**
     * This gets templates for a now legacy feature called Live Data Display
     * http://demo.blocworx.local/manage-data/live-data-display
     * We may remove this feature as we slowly we have moved all functionality to blocs.
     *
     **/
    factory.getDataDisplayTemplates = () => {
        let apiBase = apiUrl + 'get-data-display-templates';
        let config = {};
        return $http.get(apiBase, config);
    }

    /**
     * @param id
     * This deletes templates for a now legacy feature called Live Data Display
     * http://demo.blocworx.local/manage-data/live-data-display
     * We may remove this feature as we slowly we have moved all functionality to blocs.
     *
     **/
    factory.deleteDataDisplayTemplate = (id) => {
        let apiBase = apiUrl + 'delete-data-display-template';
        let config = {
            params: {
                templateID: id
            }
        };
        return $http.get(apiBase, config);
    }


    /**
     * This generates a barcode excel file using data that is uploaded via excel.
     * I believe the feature is still being used but we do not link to it
     * http://demo.blocworx.local/generate-barcodes
     * @param excelDataFile
     */
    factory.generateBarcodeSheetFromExcelUpload = (excelDataFile) => {
        let fd = new FormData();
        fd.append('excel-data', excelDataFile);
        let apiBase = apiUrl + 'generate-barcode-sheet-from-excel-upload';
        return $http.post(apiBase, fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined},
            responseType: 'blob'
        });
    }



    /**
     * This will be getting a file by passing its path.
     *
     * @param path
     */
    factory.getFileByPath = (path) => {
        let apiBase = apiUrl + 'get-file-by-path';
        let config = {
            responseType: 'blob',
            params: {
                path: path
            }
        };
        return $http.get(apiBase, config);
    }


    /**
     * This will be loading all Roles.
     */
    factory.getAllRoles = () => {
        let apiBase = apiUrl + 'get-all-roles';
        let config = {
            params: {
                userID: 0
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * This will send Email Alerts from Client Side.
     *
     * @param alertDataToSend
     * @param source
     */
    factory.sendEmailAlertFromClientSide = (alertDataToSend, source) => {
        let apiBase = apiUrl + 'send-email-alert-from-client-side';
        let data = {
            alertDataToSend: alertDataToSend,
            source: source
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will be getting Application Settings
     */
    factory.getAppSettings = () => {
        let apiBase = apiUrl + 'get-app-settings';
        let config = {};
        return $http.get(apiBase, config);
    }

    /**
     * This will be getting template File List.
     */
    factory.getTemplateFileList = () => {
        let apiBase = apiUrl + 'get-template-file-list';
        return $http.get(apiBase);
    }

    /**
     * This will be getting data for the Next Page.
     *
     * @param nextPage
     * @param currentPage
     */
    factory.dataNextPage = (nextPage, currentPage) => {
        if (nextPage === 1) {
            currentPage++;
        } else {
            if (!(currentPage <= 1)) {
                currentPage--;
            }
        }
        return currentPage;
    }

    /**
     * This will get Select Data.
     *
     * @param scanStationID
     * @param fieldSlug
     */
    factory.getSelectData = (scanStationID, fieldSlug) => {
        let apiBase = apiUrl + 'get-select-data';
        let data = {
            scanStationID: scanStationID,
            fieldSlug: fieldSlug
        };
        return $http.post(apiBase, data);
    }

    /**
     *
     * This is part of a feature in the scan station where you can write an email and send it to loads of people where the
     * email addresses are fetched from the data in the bloc. This was written as a once off and I am tempted to remove it
     * Also I dont know why I put the service function in  EnterDataService.ts
     *
     * @param recipients
     * @param emailMessage
     */
    factory.emailAlertOptionSendEmail = (recipients, emailMessage) => {
        let apiBase = apiUrl + 'email-alert-options-send-email';
        let data = {
            custom_recipients: recipients,
            alert_text: emailMessage
        };
        return $http.post(apiBase, data);
    }

    /**
     * This will delete a module.
     *
     * @param id
     */
    factory.deleteModule = (id) => {
        let apiBase = apiUrl + 'delete-module';
        let data = {
            'params': {
                id: id
            }

        };
        return $http.delete(apiBase, data);
    }

    /**
     * This will check if exist the Application Setting
     * by sending its key and saying what should it be the expected
     * result.
     *
     * @param settingKey
     * @param expectedResult
     */
    factory.hasAppSetting = (settingKey, expectedResult) => {
        let appSettingParsed = JSON.parse(localStorage.getItem('appSettings'));
        let hasSettingEnabled = false;
        if(appSettingParsed.data != null) {
            angular.forEach(appSettingParsed.data, function (settingEntry) {
                if (settingEntry.setting_key == settingKey && settingEntry.setting_value == expectedResult) {
                    hasSettingEnabled = true;
                }
            });
        }

        return hasSettingEnabled;
    }

    /**
     * When using our Parent/Child Instance feature, i.e. where a cartolytics_customer is owned by another using cartolytics_customer_owner_id
     * its possible when editing a module to give the child instance access to that module
     * The option is available at demo.blocworx.local/module/[JOBID]/edit and look for "Make This module available to child instances"
     * This function gets the list of child instances to choose from
     * @param jobId
     */
    factory.getChildInstanceModuleAccess = (jobId) => {
        let apiBase = apiUrl + 'get-child-instance-module-access';
        let config = {
            params : {
                jobId: jobId
            }
        };
        return $http.get(apiBase, config);
    }

    /**
     * When using our Parent/Child Instance feature, i.e. where a cartolytics_customer is owned by another using cartolytics_customer_owner_id
     * its possible when editing a module to give the child instance access to that module
     * The option is available at demo.blocworx.local/module/[JOBID]/edit and look for "Make This module available to child instances"
     * This function sets whether a child instance has access to a module or not using accessValue (1 or 0)
     * * @param jobId
     * @param instanceId
     * @param accessValue
     */
    factory.updateChildInstanceModuleAccess = (jobId, instanceId, accessValue) => {
        let apiBase = apiUrl + 'update-child-instance-module-access';
        let data = {
            jobId: jobId,
            instanceId: instanceId,
            accessValue: accessValue
        };
        return $http.post(apiBase, data);
    }

    // this will make sure that will be the constructor class like a controller one
    factory.$onInit();

    return factory;
}

function EnterDataConfig($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
}
