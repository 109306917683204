import * as angular from 'angular';

'use strict';

angular.module('onModelChangeModule',[])
       .directive('onModelChange', OnModelChangeDirective);

OnModelChangeDirective.$inject = ['$parse'];

function OnModelChangeDirective($parse)
{
    return {
        restrict: "A",
        require: "?ngModel",
        link: function (scope, elem, attrs, ctrl) {
            scope.$watch(attrs['ngModel'], function (newValue, oldValue) {
                if (typeof (newValue) === "undefined" || newValue == oldValue) {
                    return;
                }
                let changeExpr = $parse(attrs['onModelChange']);
                if (changeExpr) {
                    changeExpr(scope);
                }
            });
        }
    };
}
