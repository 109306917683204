import * as angular from 'angular';
import * as moment from "moment";
import * as $ from "jquery";

'use strict';

angular.module('CustomerCtrl')
    .controller('CustomerController', CustomerController);

CustomerController.$inject = ['$scope', 'Data', 'Auth', '$state', '$templateCache',
    '$window', '$interval', '$sce', 'Configuration'];

function CustomerController($scope, Data, Auth, $state, $templateCache,
                            $window, $interval, $sce, Configuration) {

    let vm = this;

    vm.$onInit = async function () {
        try {
            $templateCache.removeAll();

            vm.stateName = $state.current.name;
            if (typeof($state.current.params) != 'undefined') {
                vm.sidebarHighlighter = $state.current.params.sidebarHighlighter;
            } else {
                vm.sidebarHighlighter = '';
            }
            vm.id = $state.params.id;
            vm.apiUrl = Configuration.getApiUrl();
            vm.subDomain = Configuration.getSubDomain();
            vm.domain = Configuration.getDomain();
            vm.userID = localStorage.getItem('userID');
            vm.requestRmaData = {
                customerID: "",
                name: "",
                email: "",
                number: "",
                accountOwner: "",
                poNumber: "",
                poBackUpDocument: null,
                otherFiles: [],
                units: [],
                unitTotalPrice: 0,
                weightsMeasurements: "",
                specificRequests: "",
                failures: [],
                collectionRequired: 0,
                collectionAddress: ""
            };
            vm.rmaRequestMessage = "";

        } catch (e) {
            console.log(e);
        }
    }


    vm.addUnitType = function () {
        try {
            vm.requestRmaData.units.push({quantity: 0, unit_type: "", unit_price: 0});
            vm.updateUnitTotalPrice();

        } catch (e) {
            console.log(e);
        }

    }

    vm.deleteUnit = function (index) {
        try {
            vm.requestRmaData.units.splice(index, 1);
            vm.updateUnitTotalPrice();

        } catch (e) {
            console.log(e);
        }

    }

    vm.updateUnitType = function (unit) {
        try {
            let unitTypeName = unit.unit_type;
            for (let i = 0; i < vm.unitTypes.length; i++) {
                if (vm.unitTypes[i].name == unitTypeName) {
                    unit.unit_price = vm.unitTypes[i].price;
                }
            }
            vm.updateUnitTotalPrice();

        } catch (e) {
            console.log(e);
        }

    }

    vm.updateUnitTotalPrice = function () {
        try {
            vm.requestRmaData.unitTotalPrice = 0;
            angular.forEach(vm.requestRmaData.units, function (value) {
                vm.requestRmaData.unitTotalPrice = vm.requestRmaData.unitTotalPrice + (value.quantity * value.unit_price);
            });

        } catch (e) {
            console.log(e);
        }

    }

    vm.addFailure = function () {
        try {
            vm.requestRmaData.failures.push({serial_number: "", failure_information: ""});
        } catch (e) {
            console.log(e);
        }
    }

    vm.deleteFailure = function (index) {
        try {
            vm.requestRmaData.failures.splice(index, 1);
        } catch (e) {
            console.log(e);
        }
    }

    vm.addFile = function () {
        try {
            vm.requestRmaData.otherFiles.push({path: null});
        } catch (e) {
            console.log(e);
        }
    }

    vm.deleteFile = function (index) {
        try {
            vm.requestRmaData.otherFiles.splice(index, 1);
        } catch (e) {
            console.log(e);
        }
    }

    vm.getCustomersJobs = async function () {
        try {
            let data = await Data.getCustomersJobs();
            vm.jobList = data.data.data;

        } catch (e) {
            console.log(e);
        }

    }

    vm.getCustomerJob = async function () {
        try {
            let data = await Data.getCustomerJob(vm.id);
            vm.jobData = data.data.data[0];

        } catch (e) {
            console.log(e);
        }

    }

    vm.getCustomerScanStations = async function () {
        try {
            let data = await Data.getCustomerScanStations(vm.id);
            vm.scanStations = data.data.data;

            angular.forEach(vm.scanStations, function (value) {
                if (value.section_id == null) {
                    vm.unassigedStations = true;
                }
            });
            vm.sections = data.data.sections;

        } catch (e) {
            console.log(e);
            vm.unassigedStations = false;
        }

    }

    vm.getCustomerDataReport = async function (stationID, stationName, fieldsToReport) {
        try {
            vm.loadingReport = [];
            vm.loadingReport[stationID] = true;

            let data = await Data.getCustomerDataReport(stationID, vm.requestedReportData, fieldsToReport);
            vm.loadingReport = false;

            vm.openExcelFile(stationName, data.data);

        } catch (e) {
            console.log(e);
            alert('An Error has occurred and this report has failed to generate. Please contact the administrator.');
        }

    }

    vm.openExcelFile = function (stationName, data) {
        try {
            let date = moment().format("-MMM-Do-YYYY");

            let url = $window.URL || $window.webkitURL;
            vm.fileUrl = url.createObjectURL(data);
            let a = document.createElement("a");
            a.href = vm.fileUrl;
            a.download = stationName + date + '.xlsx';
            document.body.appendChild(a);
            a.click();


        } catch (e) {
            console.log(e);
        }

    }

    vm.getCustomerSummaryCountReport = async function (stationID, stationName, fieldsToReport) {
        try {
            vm.loadingReport = true;
            let data = await Data.getCustomerSummaryCountReport(stationID, vm.requestedReportData, fieldsToReport);
            vm.loadingReport = false;
            vm.openExcelFile(stationName + '-summary', data.data);

        } catch (e) {
            console.log(e);
        }

    }

    vm.getCustomerAntiStaticReport = async function () {
        try {
            vm.loadingReport = true;
            let data = await Data.getCustomerAntiStaticReport(vm.requestedReportData);
            vm.loadingReport = false;
            vm.openExcelFile('anti-static-report', data.data);

        } catch (e) {
            console.log(e);
        }

    }

    vm.customerLoadMoreData = async function (scanStationID, stationFields) {
        try {
            vm.scanStationFieldTypes = [];
            vm.selectedStationID = scanStationID;

            // TODO: Adrian why we have this big number here?
            let data = await Data.customerLoadMoreData(scanStationID, 1000000);

            console.log(stationFields);
            angular.forEach(stationFields, function (value) {
                vm.scanStationFieldTypes[value.field_slug] = value.field_type;
            });

            if (typeof(data) != 'undefined') {
                $('html, body').animate({
                    scrollTop: "0px"
                }, 800);
                vm.moreData = data.data.data;
                angular.forEach(vm.moreData, function (value) {
                    value.date_stamp = moment(value.created_at).format('DD/MM/YYYY');
                });
                vm.showMoreDataBox = true;
            }

            $scope.$apply();

        } catch (e) {
            console.log(e);
        }

    }

    vm.validateRequestRmaData = function () {
        try {
            if (vm.requestRmaData.customerID == "") {
                alert("Please Select a customer!");
                return false;
            }

            if (vm.requestRmaData.name == "") {
                alert("You must enter a Name!");
                return false;
            }

            if (vm.requestRmaData.number == "") {
                alert("You must enter a Contact Number!");
                return false;
            }

            if (vm.requestRmaData.email == "" || !vm.validEmail(vm.requestRmaData.email)) {
                alert("You must enter a valid Email!");
                return false;
            }

            for (let i = 0; i < vm.requestRmaData.units.length; i++) {
                let unit = vm.requestRmaData.units[i];
                if (unit.quantity == "" || unit.quantity == 0 || !Number.isInteger(unit.quantity)) {
                    alert("You must enter all Unit Quantities and make sure they are correct number values, or delete units you do not want.");
                    return false;
                }
            }

            // Check if Failures are entered
            for (let i = 0; i < vm.requestRmaData.failures.length; i++) {
                let failure = vm.requestRmaData.failures[i];
                if (failure.serial_number == "" || failure.serial_number == 0 || failure.failure_information == "") {
                    alert("You must enter all data for Failures!");
                    return false;
                }
            }

            // Check if Other files are entered
            for (let i = 0; i < vm.requestRmaData.otherFiles.length; i++) {
                let file = vm.requestRmaData.otherFiles[i];
                if (file.path == "") {
                    alert("You must select a file!");
                    return false;
                }
            }

            return true;

        } catch (e) {
            console.log(e);
        }

    }

    vm.validEmail = function (email) {
        let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(email);
    }

    vm.createRmaRequest = async function (submittedStatus) {
        try {
            if (!vm.validateRequestRmaData()) {
                return;
            }

            vm.requestRmaData.submittedStatus = submittedStatus;

            let data = await Data.createRmaRequest(vm.requestRmaData);
            if (data.data.success) {
                vm.rmaRequestMessage = data.data.message;
                if (submittedStatus == 1) {
                    vm.clearCreateRmaRequestForm();
                }
                vm.scrollTop();
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.clearCreateRmaRequestForm = function () {
        vm.requestRmaData = {
            customerID: vm.requestRmaData.customerID,
            name: "",
            email: "",
            number: "",
            accountOwner: "",
            poNumber: "",
            poBackUpDocument: null,
            otherFiles: [],
            units: [
                {quantity: 0, unit_type: "Unit 1", unit_price: 0}
            ],
            weightsMeasurements: "",
            specificRequests: "",

            failures: [],
            collectionRequired: 0,
            collectionAddress: ""
        };
    }

    vm.getUnitsWithCustomerPrices = async function () {
        try {
            let data = await Data.getUnitsWithCustomerPrices(vm.requestRmaData.customerID);
            vm.unitTypes = [];
            vm.unitsWithCustomerPrices = {};
            vm.unitsWithCustomerPrices = data.data.data;
            angular.forEach(vm.unitsWithCustomerPrices, function (value) {
                vm.unitTypes.push({
                    name: value[0],
                    price: value[1]
                });
            });

        } catch (e) {
            console.log(e);
        }

    }


    vm.scrollTop = function () {
        try {
            $('html, body').animate({
                scrollTop: "0px"
            }, 800);

        } catch (e) {
            console.log(e);
        }
    }

    vm.getSavedRMA = async function () {
        try {
            let data = await Data.getSavedRMA();
            vm.savedRMA = data.data.data;
            if (vm.savedRMA != null) {
                vm.requestRmaData = {
                    customerID: vm.savedRMA.customer_id,
                    name: vm.savedRMA.name,
                    email: vm.savedRMA.contact_email,
                    number: vm.savedRMA.contact_number,
                    anectoAccountOwner: vm.savedRMA.anecto_account_holder,
                    poNumber: vm.savedRMA.po_number,
                    poBackUpDocument: vm.savedRMA.po_attached_file,
                    otherFiles: JSON.parse(vm.savedRMA.other_files),
                    units: JSON.parse(vm.savedRMA.units),
                    unitTotalPrice: 0,
                    weightsMeasurements: vm.savedRMA.weights_and_measurements,
                    specificRequests: vm.savedRMA.specific_requests,
                    failures: JSON.parse(vm.savedRMA.failure_information),
                    collectionRequired: vm.savedRMA.collection_required,
                    collectionAddress: vm.savedRMA.collection_address
                };

                await vm.getUnitsWithCustomerPrices();

                vm.updateUnitTotalPrice();

                angular.forEach(vm.requestRmaData.otherFiles, function (value) {
                    value.already_uploaded = true;

                });

                angular.forEach(vm.requestRmaData.failures, function (value) {
                    value.already_uploaded = true;

                });

                if (vm.savedRMA.po_attached_file != null) {
                    vm.thereExistspoBackUpDocument = true;
                }
            }

        } catch (e) {
            console.log(e);
        }

    }
}

