import * as angular from 'angular';

'use strict';

angular.module('AuthService')
    .factory('Auth', AuthService);

AuthService.$inject = ['$auth', '$http', 'Configuration','$window'];

function AuthService($auth, $http, Configuration, $window) {

    let AuthFactory = this;
    let apiUrl = Configuration.getApiUrl();

    /**
     * Constructor method.
     */
    AuthFactory.$onInit = () => {
        try
        {
            // console.log('Start: Auth Services');

            AuthFactory.errorMessage ='Incorrect Username or Password';
            AuthFactory.doesNotExistUserMessage ='Incorrect Username or Password';
            AuthFactory.incorrectUsernameMessage = 'Incorrect Username or Password';
            AuthFactory.doNotHaveAccessMessage = 'Sorry, you do not have access to this account';
            AuthFactory.wrong2FACodeMessage = 'This code is incorrect, please provide a correct code or click below to resend a new code';

            AuthFactory.superUser = undefined;
            AuthFactory.loggedIn = false;

            // This will be set the username data after login
            AuthFactory.userData = undefined;
            AuthFactory.userRoles = undefined;


        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This will start the AuthService with isSuperUser true or false.
     */
    AuthFactory.loadSuperUser = async () => {

        let tokenExist = AuthFactory.tokenExists();

        // validation 1, exist the auth token, so when you are logged it out, the next validation  is not executed
        if(tokenExist && AuthFactory.superUser == undefined){
            AuthFactory.superUser = await AuthFactory.isSuperUser();
        }
    }

    /**
     * This will return the value of the main variable isSuperUser
     * after the AuthFactory.loadSuperUser method.
     */
    AuthFactory.getSuperUser = () => {
        return AuthFactory.superUser;
    }

    /**
     * This method will verify if the token exists.
     */
    AuthFactory.tokenExists = () => {
        try
        {
            let token = localStorage.getItem('satellizer_token');

            if (token != null) {
                return true;
            } else {
                return false;
            }
        } catch ( exception ) {
            console.log(exception);
        }
    }

    /**
     * This will be checking if the user is authenticated.
     */
    AuthFactory.isLoggedIn = async () => {
        try
        {
            // this will check if we already have the userData set
            if(AuthFactory.userData == undefined){

                let apiBase = apiUrl + 'authenticate';
                let config = {
                    getToken: localStorage.getItem('satellizer_token')
                };

                let userData = await $http.get(apiBase, config);

                // this is setting on the service, so we can get it anywhere
                AuthFactory.userData = userData.data;
                AuthFactory.userData.subDomain = Configuration.getSubDomain();

            }

            // this return is to keeping working whatever we had as rule before
            return AuthFactory.userData;

        } catch ( exception ) {
            console.log(exception);
        }
    }

    /**
     * This will return the user data.
     */
    AuthFactory.getUserData = () => {
        return AuthFactory.userData;
    }

    /**
     * This will be checking if we have a customer data name, so if
     * it is true we must be a customer.
     */
    AuthFactory.isCustomer = () => {
        return AuthFactory.userData != undefined && AuthFactory.userData.customer_name != null ?  1 : 0;
    }

    /**
     * This will be checking if exist the role.
     *
     * @param role
     */
    AuthFactory.hasRole = (role) => {
        try
        {
            let apiBase = apiUrl + 'checkrole/' + role;
            let config = {};
            let data = {
                role: role
            };
            return $http.post(apiBase, data, config);

        } catch ( exception ) {
            console.log(exception);
        }
    }

    /**
     * This will be getting the user roles.
     */
    AuthFactory.getUserRoles = async () => {
        try
        {
            // this will check if we already have the userData set
            if(AuthFactory.userRoles == undefined && AuthFactory.tokenExists()){
                let apiBase = apiUrl + 'get-user-roles';
                let config = {};
                let roles = await $http.get(apiBase, config);

                if(roles.status = 200 && roles.data != undefined && roles.data.data != undefined) {
                    let userRoles = [];
                    // adding the admin rule
                    angular.forEach(roles.data.data, function (value) {
                        userRoles.push(value.slug);
                        if(value.slug == 'instance-admin') {
                            userRoles.push('admin');
                        }
                    });

                    // this is setting on the service, so we can get it anywhere
                    AuthFactory.userRoles = userRoles;
                }
            }

            // this return is to keeping working whatever we had as rule before
            return AuthFactory.userRoles;

        } catch ( exception ) {
            console.log(exception);
        }


    }

    /**
     * This will be doing the logout action.
     *
     * @param token
     */
    AuthFactory.logout = (token) => {
        try
        {
            let apiBase = apiUrl + 'logout';
            let config = {};
            let data = {
                token: token
            };
            return $http.post(apiBase, data, config);

        } catch ( exception ) {
            console.log(exception);
        }
    }

    /**
     * This will be responsible to compare the versions.
     *
     * @param version
     */
    AuthFactory.compareVersions = (version) => {
        try
        {
            let apiBase = apiUrl + 'compare-versions';
            let config = {
                params: {
                    version: version
                }
            };
            return $http.get(apiBase, config);

        } catch ( exception ) {
            console.log(exception);
        }
    }

    /**
     * This will be checking if is it a super user.
     * @param version
     */
    AuthFactory.checkSuperUser = () => {
        try
        {
            let apiBase = apiUrl + 'check-superuser';

            return $http.post(apiBase);

        } catch ( exception ) {
            console.log(exception);
        }
    }

    /**
     * This will be checking if is it a super user.
     */
    AuthFactory.isSuperUser = async () => {
        try
        {
            let apiBase = apiUrl + 'is-superuser';
            let data = await $http.post(apiBase);
            if (data !== undefined && data.data === 1) {
                return true;
            } else {
                return false;
            }

        } catch ( exception ) {
            console.log(exception);
            return false;
        }
    }

    /**
     * This will be dealing with the login errors on the catch part of the AuthController => Login.
     * @param exception
     */
    AuthFactory.getLoginError = (exception) => {

        let loginErrorMessage = AuthFactory.errorMessage;
        let exceptionData = undefined;

        // Checking if we have the base structure
        if (exception != undefined && exception.data != undefined && exception.data.error != undefined) {
            exceptionData = exception.data.error;
        }

        if (exception != undefined && exception.type != undefined ) {
            exceptionData = exception.type;
        }

        switch (exceptionData) {

            case "wrong_2_factor_authentication":
                loginErrorMessage = AuthFactory.wrong2FACodeMessage;
                break;

            case "does_not_exist_user":
                loginErrorMessage = AuthFactory.doesNotExistUserMessage;
                break;

           case "invalid_credentials":
                loginErrorMessage = AuthFactory.incorrectUsernameMessage;
                break;

            case "no_customer_access":
                loginErrorMessage = AuthFactory.doNotHaveAccessMessage;
                break;

            case "could_not_create_token":
                if (exception.data.message != undefined) {
                    loginErrorMessage = exception.data.message;
                }
                break;

            default :
                loginErrorMessage = AuthFactory.errorMessage;
        }

        return loginErrorMessage;

    }

    /*
     * This method will be checking ig exist the session item logged.
     *
     */
    AuthFactory.isUserLoggedIn =  () => {
        const logged = JSON.parse(sessionStorage.getItem('logged'));
        return logged === true ? true : false;
    }

    /**
     * This will be checking if the role failed or not.
     */
    AuthFactory.roleFailed =  () => {

        let roleFailed = JSON.parse($window.localStorage.getItem('roleFailed'));

        return roleFailed == true ? true : false;
    }

    // this will make sure that will be the constructor class like a controller one
    AuthFactory.$onInit();

    return AuthFactory;
}
