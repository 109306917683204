import * as angular from 'angular';

'use strict';

angular.module('UserService')
       .factory('User', UserService);

UserService.$inject = ['$http', 'Configuration'];

function UserService($http, Configuration) {

    let apiUrl = Configuration.getApiUrl();

    return {
        getUsers: function getUsers() {
            let apiBase = apiUrl + 'manage-users';
            let config = {};
            return $http.get(apiBase, config);
        },
        getUser: function getUser(id) {
            let apiBase = apiUrl + 'manage-users/' + id;
            let config = {
                params: {id: id}
            };
            return $http.get(apiBase, config);
        },
        editUser: function editUser(newUserData) {
            let apiBase = apiUrl + 'manage-users/' + newUserData.id + '/edit';
            let config = {
                params: {
                    email: newUserData.email,
                    name: newUserData.name,
                    userRoles: JSON.stringify(newUserData.userRoles)
                }
            };
            return $http.get(apiBase, config);
        },
        editPassword: function editPassword(id, password) {
            let apiBase = apiUrl + 'manage-users/' + id + '/change-password';
            let config = {};
            let data = {
                password: password
            };
            return $http.post(apiBase, data, config);
        },
        addUser: function addUser(newUserData) {
            let apiBase = apiUrl + 'manage-users/create';
            let config = {
                params: {
                    email: newUserData.email,
                    name: newUserData.name,
                    customerName: newUserData.customerName,
                    password: newUserData.password,
                    userRoles: JSON.stringify(newUserData.userRoles)
                }
            };
            return $http.get(apiBase, config);
        },
        validatePassword: function validatePassword(password) {
            if (!password) {
                console.log("Password must be non-empty")
                return false;
            }
            const punct = "[£$&+,:;=?@#|'<>.^*()%!-]";
            let usedUpSpecialCharacters = [];
            let specialCharacterCount = 0;
            for(let i = 0; i < password.length; i++){
                if(punct.includes(password[i]) && usedUpSpecialCharacters.indexOf(password[i]) == -1){
                    specialCharacterCount++;
                    usedUpSpecialCharacters.push(password[i]);
                }
            }

            let hasLowerCaseCharacter = /[a-z]/.test(password);
            let hasUpperCaseCharacter = /[A-Z]/.test(password);
            let hasDigitCharacter = /[0-9]/.test(password);

            return hasLowerCaseCharacter
                && hasUpperCaseCharacter
                && hasDigitCharacter
                && specialCharacterCount>1
                && password.length >= 8;
        },
        validateEmail: function validateEmail(email) {
            if (!email) {
                return false;
            }
            let emailCharacterRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let validEmail = emailCharacterRegex.test(email);

            return validEmail;
        },
        /**
         * Calls backend to query the Users DB.
         *
         * @param userName inputted email
         * @returns {HttpPromise} result of the DB query
         */
        checkIfUserExists: function checkIfUserExists(userName) {
            let apiBase = apiUrl + 'check-username';
            let body = {
                userName: userName
            };
            return $http.post(apiBase, body);
        },

        updateUser: function (updateDetails) {
            let apiBase = apiUrl + 'update-user';
            let body = {
                updateDetails: updateDetails
            };
            return $http.post(apiBase, body);
        }
    }
}
