import * as angular from 'angular';

'use strict';

angular.module('upperCaseModule',[])
       .directive('uppercased', UppercaseDirective);

UppercaseDirective.$inject = [];

function UppercaseDirective()
{
    return {
        require: 'ngModel',
        scope: {
            uppercasesetting: '@'
        },
        link: function (scope, element, attrs, modelCtrl) {
            if (scope.uppercasesetting != 'mixed-case' && scope.uppercasesetting
                != 'capitalize') {
                modelCtrl.$parsers.push(function (input) {
                    return input ? input.toUpperCase() : "";
                });
                element.css("text-transform", "uppercase");
            } else if (scope.uppercasesetting == 'capitalize') {
                modelCtrl.$parsers.push(function (input) {
                    return input ? input.substr(0, 1).toUpperCase() + input.substr(1)
                        : "";
                });
            }
        }
    };
}
