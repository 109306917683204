import * as angular from 'angular';
import * as moment  from "moment";

'use strict';

angular.module('DateService')
       .service('DateService', DateService);

DateService.$inject = ['$auth', '$http', 'Configuration'];

function DateService($auth, $http, Configuration) {

    let DateService = this;

    /**
     * Constructor method.
     */
    DateService.$onInit = () => {
        try
        {

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This method will loop an array of data and
     * by checking for all strings if the string can be
     * a match on the regex so we can build a date
     * from a correct new Date, so we can
     * use our main formatDate.
     *
     * @param arrayOfData
     */
    DateService.updateDatesInArray =  (arrayOfData) => {

        let dateRegex = /[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{2,4}/g

        for (const key in arrayOfData) {

            let valueToCheck = arrayOfData[key];

            if( typeof valueToCheck == 'string'){

                if(valueToCheck.match(dateRegex)){
                    const [day, month, year] = arrayOfData[key].split('/');
                    let newDate = new Date(`${year}-${month}-${day}`);
                    arrayOfData[key] = DateService.formatDate(newDate)
                }
            }

        }

        return arrayOfData;

    }

    /**
     * This will format the Date.
     * @param data
     */
    DateService.formatDate =  (data) => {

        // Getting a basic date if data isn't set yet.
        let returnedFormattedData = data == undefined ? new Date() : data;

        // This will make sure that the is correct validated the date format
        returnedFormattedData.toLocaleString(undefined,{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });

        return returnedFormattedData.toLocaleDateString('en-GB');
    }

    /**
     * This will mounting the Date object so we can have a valid date
     * format.
     */
    DateService.currentDateToCheck =  (dateSrcData, addNumberOfDays) => {

        // If we get the date instead of the string with the date in it
        if(dateSrcData instanceof Date){
            dateSrcData = dateSrcData.toLocaleDateString();
            console.log(dateSrcData)
        }

        // Step 1 : get the src from the current date or  anew one if the dateSrcData is empty
        let currentDateToCheck = undefined

        if(dateSrcData != undefined){
            const [day, month, year] = dateSrcData.split('/');
            currentDateToCheck = new Date(`${year}-${month}-${day}`);
        } else {
            currentDateToCheck = new Date();
        }

        // formatting with minimum 2 characters for month and day
        currentDateToCheck.toLocaleString(undefined,{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });

        // Step 2 adding the new number of days to add
        currentDateToCheck.setDate(+currentDateToCheck.getDate() + +addNumberOfDays);

        // Check if it is a float
        let decimals = +addNumberOfDays - Math.floor(addNumberOfDays);

        if(decimals != 0){
            // we sometimes want to add the number of days to current date, but also consider decimals
            // this allows us to add, for example, 1.5 days to the current time, useful for timers/hours tracking/time in/time outs etc
            currentDateToCheck = moment(currentDateToCheck).add((24*decimals), 'hours').toDate();
        }
        return currentDateToCheck;
    }

    // this will make sure that will be the constructor class like a controller one
    DateService.$onInit();

    return DateService;
}
