import * as angular from 'angular';

'use strict';

angular.module('formceptionModule',[])
       .directive('formception', FormceptionDirective);

FormceptionDirective.$inject = ['$compile', '$parse'];

function FormceptionDirective($compile, $parse)
{
    return {
        link: function (scope, element, attr) {
            const scanStationID = $parse(element.attr('formception'))(scope);
            scope.$parent.scanStation.filterDataOptions = scope.$parent.data.dataFilteringData;
        }
    };
}
