import * as angular from 'angular';

'use strict';

angular.module('complianceService')
       .factory('compliance', ComplianceService)
       .config(ComplianceConfig);

ComplianceService.$inject = ['$http', 'Configuration'];

function ComplianceService($http, Configuration) {

    let linkedJobSlug;
    let apiUrl = Configuration.getApiUrl();

    return {
        createModule: function createModule(createModuleData) {
            let apiBase = apiUrl + 'module';
            return $http.post(apiBase, createModuleData);
        },
        updateModule: function updateModule(moduleData, moduleID) {
            let apiBase = apiUrl + 'module/' + moduleID;
            let data = {
                moduleID: moduleID,
                moduleData: moduleData
            };
            return $http.put(apiBase, data);
        },
        deleteModule: function deleteModule(moduleID) {
            let apiBase = apiUrl + 'module/' + moduleID;
            let data = {
                moduleID: moduleID
            };
            return $http.delete(apiBase, data);
        },
        getModules: function getModules(status) {
            let apiBase = apiUrl + 'module';
            let config = {
                params: {
                    status: status
                }
            };
            return $http.get(apiBase, config);
        },

        getModule: function getModule(id, moduleSlug) {
            let apiBase = apiUrl + 'module/' + id;
            let config = {
                params: {
                    id: id,
                    moduleSlug: moduleSlug
                }
            };
            return $http.get(apiBase, config);
        },
        uploadModuleFile: function uploadModuleFile(moduleFileTitle, file, moduleID) {
            let fd = new FormData();
            fd.append('moduleFile', file);
            fd.append('moduleFileTitle', moduleFileTitle);
            fd.append('moduleID', moduleID);
            fd.append('file', file);

            let apiBase = apiUrl + 'upload-module-file';
            return $http.post(apiBase, fd, {transformRequest: angular.identity, headers: {'Content-Type': undefined}});
        },
        getUploadedModuleFiles: function getUploadedModuleFiles(moduleID) {
            let apiBase = apiUrl + 'get-uploaded-module-files';
            let config = {
                params: {
                    moduleID: moduleID
                }
            };
            return $http.get(apiBase, config);
        },
        deleteModuleFile: function deleteModuleFile(id) {
            let apiBase = apiUrl + 'delete-module-file';
            let config = {
                params: {
                    id: id
                }
            };
            return $http.delete(apiBase, config);
        },
        uploadUserFile: function uploadUserFile(fieldID, file) {
            let fd = new FormData();
            fd.append('userFile', file);
            fd.append('fieldID', fieldID);
            fd.append('file', file);

            let apiBase = apiUrl + 'upload-user-file';
            return $http.post(apiBase, fd, {transformRequest: angular.identity, headers: {'Content-Type': undefined}});
        },
        getUsersFilesForQuestion: function getUsersFilesForQuestion(questionSlug, moduleSlug) {
            let apiBase = apiUrl + 'get-users-files-for-question';
            let config = {
                params: {
                    questionSlug: questionSlug,
                    moduleSlug: moduleSlug
                }
            };
            return $http.get(apiBase, config);
        },
        deleteUserFile: function deleteUserFile(fileName, fieldID) {
            let apiBase = apiUrl + 'delete-user-file';
            let config = {
                params: {
                    fileName: fileName,
                    fieldID: fieldID
                }
            };
            return $http.delete(apiBase, config);
        },
        getQuestions: function getQuestions(moduleID, moduleSlug) {
            let apiBase = apiUrl + 'get-questions';
            let config = {
                params: {
                    moduleID: moduleID,
                    moduleSlug: moduleSlug
                }
            };
            return $http.get(apiBase, config);
        },
        addNewQuestion: function addNewQuestion(moduleID, question, newSectionID) {
            let apiBase = apiUrl + 'add-new-question';
            let data = {
                moduleID: moduleID,
                question: question,
                newSectionID: newSectionID
            };
            return $http.post(apiBase, data);
        },
        updateQuestionOrderID: function updateQuestionOrderID(questionID, direction) {
            let apiBase = apiUrl + 'update-question-order-id';
            let data = {
                questionID: questionID,
                direction: direction
            };
            return $http.post(apiBase, data);
        },
        updateSectionOrderID: function updateQuestionOrderID(sectionID, moduleID, direction) {
            let apiBase = apiUrl + 'update-section-order-id';
            let data = {
                sectionID: sectionID,
                moduleID: moduleID,
                direction: direction
            };
            return $http.post(apiBase, data);
        },
        updateSectionName: function updateSectionName(sectionID, sectionName) {
            let apiBase = apiUrl + 'update-section-name';
            let data = {
                sectionID: sectionID,
                sectionName: sectionName
            };
            return $http.post(apiBase, data);
        },
        updateQuestionName: function updateQuestionName(questionID, questionName) {
            let apiBase = apiUrl + 'update-question-name';
            let data = {
                questionID: questionID,
                questionName: questionName
            };
            return $http.post(apiBase, data);
        },
        updateField: function updateField(fieldID, updateType, value) {
            let apiBase = apiUrl + 'update-field';
            let data = {
                fieldID: fieldID,
                updateType: updateType,
                value: value
            };
            return $http.post(apiBase, data);
        },
        addNewSection: function addNewSection(moduleID, sectionName) {
            let apiBase = apiUrl + 'add-new-section';
            let data = {
                moduleID: moduleID,
                sectionName: sectionName
            };
            return $http.post(apiBase, data);
        },
        deleteQuestion: function deleteQuestion(questionID) {
            let apiBase = apiUrl + 'delete-question';
            let config = {
                params: {
                    questionID: questionID
                }
            };
            return $http.delete(apiBase, config);
        },
        deleteSection: function deleteSection(sectionID) {
            let apiBase = apiUrl + 'delete-section';
            let config = {
                params: {
                    sectionID: sectionID
                }
            };
            return $http.delete(apiBase, config);
        },
        getModuleUsers: function getModuleUsers(moduleID) {
            let apiBase = apiUrl + 'get-module-users';
            let config = {
                params: {
                    moduleID: moduleID
                }
            };
            return $http.get(apiBase, config);
        },
        addModuleUser: function addModuleUser(moduleID, selectedUserID) {
            let apiBase = apiUrl + 'add-module-user';
            let data = {
                moduleID: moduleID,
                selectedUserID: selectedUserID
            };
            return $http.post(apiBase, data);
        },
        deleteUserFromModule: function deleteHouseKeepingAuditTask(userToModuleID) {
            let apiBase = apiUrl + 'delete-user-from-module';
            let config = {
                params: {
                    userToModuleID: userToModuleID
                }
            };
            return $http.delete(apiBase, config);
        },
        getUsersQuestions: function getUsersQuestions(moduleID) {
            let apiBase = apiUrl + 'get-users-questions';
            let config = {
                params: {
                    moduleID: moduleID
                }
            };
            return $http.get(apiBase, config);
        },
        openUserFile: function openUserFile(fieldID, fileName) {
            let apiBase = apiUrl + 'get-file/user-file';
            let config = {
                responseType: 'blob',
                params: {
                    fieldID: fieldID,
                    fileName: fileName
                }
            };
            return $http.get(apiBase, config);
        },
        openModuleFile: function openModuleFile(moduleID, moduleSlug, fileName) {
            let apiBase = apiUrl + 'get-file/module-files';
            let config = {
                responseType: 'blob',
                params: {
                    moduleID: moduleID,
                    moduleSlug: moduleSlug,
                    fileName: fileName
                }
            };
            return $http.get(apiBase, config);
        },
        getUsersModules: function getUsersModules() {
            let apiBase = apiUrl + 'get-users-modules';
            let config = {};
            return $http.get(apiBase, config);
        },
        updateQuestion: function updateQuestion(moduleID, questionFields, fieldsToDelete, questionID, fieldRulesToAdd) {
            let apiBase = apiUrl + 'update-question';
            let data = {
                'moduleID': moduleID,
                'questionFields': questionFields,
                'fieldsToDelete': fieldsToDelete,
                'questionID': questionID,
            };
            return $http.post(apiBase, data);
        },
        getQuestionData: function getQuestionData(id, questionSlug, moduleSlug, moduleID) {
            let apiBase = apiUrl + 'get-question-data';
            let config = {
                params: {
                    id: id,
                    questionSlug: questionSlug,
                    moduleSlug: moduleSlug,
                    moduleID: moduleID
                }
            };
            return $http.get(apiBase, config);
        },
        undo: function undo(questionID) {
            let apiBase = apiUrl + 'undo';
            let config = {
                handleError: true,
                params: {
                    questionID: questionID
                }
            };
            return $http.get(apiBase, config);
        },
        updateQuestionSetting: function updateQuestionSetting(settingKey, questionID, settingValue) {
            let apiBase = apiUrl + 'update-question-setting';
            let data = {
                settingKey: settingKey,
                questionID: questionID,
                settingValue: settingValue
            };
            return $http.post(apiBase, data);
        },
        getSections: function getSections(moduleID, moduleSlug) {
            let apiBase = apiUrl + 'get-sections';
            let config = {
                params: {
                    moduleID: moduleID,
                    moduleSlug: moduleSlug
                }
            };
            return $http.get(apiBase, config);
        },
        getModuleIDFromSlug: function getModuleIDFromSlug(slug) {
            let apiBase = apiUrl + 'get-module-id-from-slug';
            let config = {
                params: {
                    slug: slug,
                }
            };
            return $http.get(apiBase, config);
        },
        saveAnswers: function saveAnswers(questionSlug, moduleID, questionFieldAnswers) {
            let apiBase = apiUrl + 'save-answers';
            let data = {
                questionSlug: questionSlug,
                moduleID: moduleID,
                questionFieldAnswers: questionFieldAnswers
            };
            return $http.post(apiBase, data);
        },
        getAnswers: function getAnswers(questionSlug, moduleID, moduleSlug) {
            let apiBase = apiUrl + 'get-answers';
            let config = {
                params: {
                    questionSlug: questionSlug,
                    moduleID: moduleID,
                    moduleSlug: moduleSlug
                }
            };
            return $http.get(apiBase, config);
        },
        getGraphData: function getGraphData(sectionID, moduleSlug, questionSlug) {
            let apiBase = apiUrl + 'get-graph-data';
            let config = {
                params: {
                    sectionID: sectionID,
                    questionSlug: questionSlug,
                    moduleSlug: moduleSlug
                }
            };
            return $http.get(apiBase, config);
        },
        getTasksBySectionID: function getTasksBySectionID(sectionID, moduleSlug, questionSlug) {
            let apiBase = apiUrl + 'get-tasks-by-section-id';
            let config = {
                params: {
                    sectionID: sectionID,
                    moduleSlug: moduleSlug,
                    questionSlug: questionSlug
                }
            };
            return $http.get(apiBase, config);
        },
        getReviewPageNavigation: function getReviewPageNavigation(sectionID, questionSlug, moduleSlug) {
            let apiBase = apiUrl + 'get-review-page-navigation';
            let config = {
                params: {
                    sectionID: sectionID,
                    slug: moduleSlug,
                    questionSlug: questionSlug
                }
            };
            return $http.get(apiBase, config);
        },
        getUserAssessments: function getUserAssessments() {
            let apiBase = apiUrl + 'get-user-assessments';
            let config = {
                params: {}
            };
            return $http.get(apiBase, config);
        },
        submitAssessment: function submitAssessment(newAssessmentData, instanceID) {
            let apiBase = apiUrl + 'submit-assessment';
            let data = {
                newAssessmentData: newAssessmentData,
                instanceID: instanceID
            };
            return $http.post(apiBase, data);
        },
        getModulesAssessments: function getModulesAssessments() {
            let apiBase = apiUrl + 'get-modules-assessments';
            let config = {params: {}};
            return $http.get(apiBase, config);
        },
        chartReport: function chartReport(moduleID, canvasArray) {
            let apiBase = apiUrl + 'chart-report/' + moduleID;
            let config = {
                responseType: 'blob'
            };
            let body = {
                canvasArray: canvasArray
            };
            return $http.post(apiBase, body, config);
        },
        setLinkedJob: function setLinkedJob(jobSlug) {
            linkedJobSlug = jobSlug;
        },
        getLinkedJob: function getLinkedJob() {
            return linkedJobSlug;
        },
    }
}

function ComplianceConfig($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
}