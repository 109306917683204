import * as angular from 'angular';

'use strict';

angular.module('fileModelModule',[])
       .directive('fileModel', FileModelDirective);

FileModelDirective.$inject = ['$parse'];

function FileModelDirective($parse)
{
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var model = $parse(attrs.fileModel);
            var modelSetter = model.assign;
            var isMultiple = attrs.multiple;
            if (isMultiple) {
                element.bind('change', function () {
                    var values = [];
                    angular.forEach(element[0].files, function (item) {

                        values.push(item);
                    });
                    scope.$apply(function () {
                        modelSetter(scope, values);
                    });
                })
            } else {
                element.bind('change', function () {
                    scope.$apply(function () {
                        modelSetter(scope, element[0].files[0]);
                    });
                });
            }
        }
    };
}


